import {
  CreateParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  HttpError,
  SortPayload,
  fetchUtils,
  addRefreshAuthToDataProvider,
} from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import { captureException } from '@sentry/react';

import { authTokenStore } from '../authTokenStore';
import errorMessages from '../../constants/errorMessages';
import { refreshAuth } from '../Authprovider';

const expectedErrorStatuses = [401, 404];
const isExpectedAPIError = (e: HttpError) => {
  if (expectedErrorStatuses.includes(e.status)) return true;

  if (Object.keys(errorMessages).includes(e.message)) return true;

  return false;
};

type RestrictedOptions = fetchUtils.Options & {
  headers?: Headers;
}

const httpClient = async (url: string, options: fetchUtils.Options = {}) => {
  const finalOptions = {
    ...options,
    credentials: 'include',
  } as RestrictedOptions;
  if (!finalOptions.headers) {
    finalOptions.headers = new Headers({ Accept: 'application/json' });
  }
  const token = authTokenStore.getToken();
  finalOptions.headers.set('authorization', `Bearer ${token}`);

  try {
    const result = await fetchUtils.fetchJson(url, finalOptions);
    return result;
  } catch (e) {
    if (e instanceof HttpError) {
      if (!isExpectedAPIError(e)) {
        const extra = {
          url,
          options: {
            ...finalOptions,
            headers: Object.fromEntries(finalOptions.headers),
          },
          response: e.body,
          status: e.status,
        };
        const tags = {
          status: e.status,
        };
        captureException(e, { extra, tags });
      }
    } else {
      captureException(e);
    }
    throw e;
  }
};

const getDataProvider = (apiUrl: string) => {
  const defaultDataProvider = jsonServerProvider(apiUrl, httpClient);
  const overriddenDataProvider = {
    ...defaultDataProvider,
    getList: (resource: string, params: GetListParams) => {
      const isDefaultSort = params.sort.field === 'id';
      return defaultDataProvider.getList(resource, {
        ...params,
        sort: isDefaultSort ? {} as SortPayload : params.sort,
      });
    },
    getManyReference: (resource: string, params: GetManyReferenceParams) => {
      const isDefaultSort = params.sort.field === 'id';
      return defaultDataProvider.getManyReference(resource, {
        ...params,
        sort: isDefaultSort ? {} as SortPayload : params.sort,
      });
    },
    getMany: (
      resource:string,
      params: GetManyParams,
    ) => defaultDataProvider.getList(resource, {
      filter: { id: params.ids },
      pagination: { perPage: params.ids.length, page: 1 },
      sort: {} as SortPayload,
    }),
    create: async (resource: string, params: CreateParams) => {
      const { json } = await httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      });

      return {
        data: {
          id: json.id ?? window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16),
          ...json,
        },
      };
    },
  };

  return addRefreshAuthToDataProvider(overriddenDataProvider, refreshAuth);
};

export default getDataProvider;
