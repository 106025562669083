import React, { useCallback, useMemo } from 'react';
import {
  ListItemText,
  List,
  ListItemButton,
  ListSubheader,
  InputAdornment,
  Box,
} from '@mui/material';
import { TextInput } from 'react-admin';

import SearchIcon from '@mui/icons-material/Search';
import { useFormContext, useWatch } from 'react-hook-form';

import InfoPopover from '../InfoPopover';
import { ruleDescriptions } from '../../rules/ruleFeatures';
import { RuleNames } from '../../../constants/ruleNames';
import { RuleCategories } from '../../rules/categories/ruleCategories';
import { RuleSubCategories } from '../../rules/categories/ruleSubCategories';

const containsText = (text: string, searchText: string) => (
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
);

const RulePathList = ({
  choices,
  source,
  showAllOption,
}: {
  choices: {
    id: RuleCategories | RuleSubCategories | RuleNames;
    name: string;
  }[]
  source: string;
  showAllOption?: boolean;
}) => {
  const { setValue } = useFormContext();
  const currentValue: RuleCategories | RuleSubCategories | RuleNames | 'all' = useWatch({ name: source });
  const handleListItemClick = useCallback((id: RuleCategories | RuleSubCategories | RuleNames | 'all') => {
    setValue(source, id);
  }, [setValue, source]);

  const searchSource = `${source}_search`;
  const searchText: string | undefined = useWatch({ name: searchSource });
  const filterChoices = useMemo(() => choices
    .sort((a, b) => (b.name > a.name ? -1 : 1))
    .filter((option) => containsText(option.name, searchText ?? '')), [choices, searchText]);

  const isSelected = useCallback((id: string) => currentValue === id, [currentValue]);

  return (
    <List
      color="secondary"
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ListSubheader>
        <TextInput
          label="Search"
          fullWidth
          source={searchSource}
          helperText={false}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </ListSubheader>
      <Box overflow="auto" marginTop={2}>
        {showAllOption && (
          <ListItemButton
            key="all"
            selected={isSelected('all')}
            onClick={() => handleListItemClick('all')}
          >
            <ListItemText primary="All" />
          </ListItemButton>
        )}
        {filterChoices.map((choice) => (
          <ListItemButton
            key={choice.id}
            selected={isSelected(choice.id)}
            onClick={() => handleListItemClick(choice.id)}
          >
            <ListItemText primary={choice.name} />
            {ruleDescriptions[choice.id] && (
              <InfoPopover title={choice.name} content={ruleDescriptions[choice.id]!} />
            )}
          </ListItemButton>
        ))}
      </Box>
    </List>
  );
};

export default RulePathList;
