import React from 'react';

import { useWatch } from 'react-hook-form';

import Car, { CarValues } from './Car';
import ToggleButton from './ToggleButton';

import Accordion from '../../../../../../layout/Accordion';

export type FormWithGoodsValues = {
  includeGoods: true;
  goods_services: {
    item: CarValues
  }[];
} |{
  includeGoods: false;
  goods_services?: [];
}

const GoodsAccordion = () => {
  const includeGoods = useWatch({ name: 'includeGoods' });
  return (
    <Accordion label="Assets" actions={<ToggleButton />}>
      {includeGoods && <Car />}
    </Accordion>
  );
};

export default GoodsAccordion;
