import React, { memo } from 'react';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { NodeProps } from 'reactflow';

import { NodeData } from './utils';
import Node from './Node';

const AccountNode = ({ data, selected }: NodeProps<NodeData>) => (
  <Node
    selected={selected}
    data={data}
    Icon={AccountBalanceIcon}
  />
);

export default memo(AccountNode);
