import React, { useEffect } from 'react';

import {
  Loading,
  required,
  SelectInput,
  TextInput,
  useGetOne,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  Box,
  Grid,
} from '@mui/material';

import { Alpha3Code } from 'i18n-iso-countries';

import RootFields, { TransactionPartyRootFormValues } from './RootFields';

import { legalFormTypeChoices, LegalFormTypes } from '../../../../../../../constants/legalForms';
import countries from '../../../../../../../constants/countries';
import languages from '../../../../../../../constants/languages';
import { IBusiness } from '../../../../../../../utilities/schemas/business';

export type EntityFormValues = TransactionPartyRootFormValues & ({
  id?: string;
  isMyClient: true;
  entity: {
    name: string;
    incorporation_number: string;
    incorporation_country_code: Alpha3Code;
    incorporation_legal_form: LegalFormTypes;
  };
} | {
  id?: string;
  isMyClient: false;
  entity: {
    name: string;
    incorporation_number?: string;
    incorporation_country_code?: Alpha3Code | 'unknown';
    incorporation_legal_form?: LegalFormTypes;
  };
})

const Entity = ({
  direction,
}: {
  direction: string
}) => {
  const defaultEntityId = useWatch({ name: `${direction}.id` });
  const { data, isLoading } = useGetOne<IBusiness>(
    'businesses',
    { id: defaultEntityId! },
    { enabled: !!defaultEntityId },
  );
  const isMyClient = useWatch({ name: `${direction}.isMyClient` });
  const entitySource = `${direction}.entity`;
  const { resetField } = useFormContext();

  useEffect(() => {
    if (data) {
      resetField(entitySource, {
        defaultValue: {
          name: data.name,
          incorporation_legal_form: data.incorporationLegalForm,
          incorporation_number: data.swedishOrganizationNumber,
        },
      });
    }
  }, [data, resetField, entitySource]);

  if (isLoading) {
    return (
      <Box width="100%" height="100%" sx={{ transform: 'translate(0%, 100%)' }}>
        <Loading />
      </Box>
    );
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <RootFields direction={direction} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextInput fullWidth source={`${entitySource}.name`} label="Name" validate={required()} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextInput fullWidth source={`${entitySource}.incorporation_number`} label="Incorporation Number" validate={isMyClient ? required() : undefined} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SelectInput
          choices={isMyClient ? countries : [...countries, { id: 'unknown', name: 'Unknown' }]}
          fullWidth
          source={`${entitySource}.incorporation_country_code`}
          label=" Incorporation Country "
          validate={isMyClient ? required() : undefined}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SelectInput
          choices={legalFormTypeChoices(languages.EN)}
          source={`${entitySource}.incorporation_legal_form`}
          label=" Incorporation Legal Form "
          validate={isMyClient ? required() : undefined}
        />
      </Grid>
    </Grid>
  );
};

export default Entity;
