import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import {
  Form,
  HttpError,
  Identifier,
  minValue,
  NumberInput,
  RaRecord,
  required,
  useGetList,
  useGetOne,
  useNotify,
  useResourceContext,
  useStore,
} from 'react-admin';

import ReactApexChart from 'react-apexcharts';
import { DateTime } from 'luxon';

import {
  Grid,
  Card,
  CardHeader,
  Divider,
  Box,
  CircularProgress,
} from '@mui/material';

import { isEmpty, isNil, isNumber } from 'lodash';

import useDialogStatus from '../../../hooks/useDialogStatus';
import useDebounce from '../../../hooks/useDebounce';

import NoResults from '../../layout/NoResults';
import DetailedTransactionTable from '../DetailedTransactionTable';
import Params from '../Params';

import { requestTypes } from '../../../constants/requestTypes';
import theme from '../../../theme';
import { ApexChartOnClickHandler, round2Digits } from './DailySum';
import { EntityTypes, entityTypesFromResource } from '../../../constants/entityTypes';
import { TransactionMonitoringThresholds } from '../../../utilities/schemas/datapoints/transactionMonitoringThresholds';
import conflictFields from '../../../constants/conflictFields';
import SubmitOnChange from '../../utils/SubmitOnChange';

const useGetGraphData = <T extends unknown>(
  data: {
    id: string;
    type: string;
    name: string;
    data: {
      x: string;
      y: number;
      frequency: number;
      currency: string;
    }[]
  }[] | undefined,
  onClickGraph: ApexChartOnClickHandler<T>,
  filter: {
    from: string;
    to: string;
    currency: string;
    entityId: string;
    entityType: EntityTypes;
    requestType: typeof requestTypes.TRANSACTION;
    transactionTypeConfiguration?: string;
  },
  windowSize: number | null,
) => {
  const [hasData, setHasData] = useState(false);
  const [options, setOptions] = useState<ReactApexChart['props']['options']>();
  const [series, setSeries] = useState<ReactApexChart['props']['series']>();

  const [
    showExpectedBehavior,
  ] = useStore('transactionShowExpectedBehavior', false);

  const {
    entityId,
    transactionTypeConfiguration,
    currency,
    to,
  } = filter;
  const resource = useResourceContext();
  const entityType = entityTypesFromResource[resource];

  const [
    overriddenExpectedBehaviours,
  ] = useStore(`transactionOverriddenExpectedBehaviours-${entityId}`, new Map<string | undefined, TransactionMonitoringThresholds | undefined>());

  const identificationInfo = transactionTypeConfiguration ? {
    transactionTypes: JSON.parse(transactionTypeConfiguration),
  } : undefined;
  const { data: expectedTMThresholdDatapoint } = useGetOne<{
    id: string;
    status: 'resolved' | 'conflict' | 'empty';
    selectedValue: TransactionMonitoringThresholds | undefined;
  }>('data-points/status', {
    id: `${conflictFields.TRANSACTION_MONITORING_THRESHOLD}/${entityId}/${entityType}${identificationInfo ? `/${JSON.stringify(identificationInfo)}` : ''}`,
  }, {
    enabled: showExpectedBehavior,
  });

  const expectedInfo = useMemo(() => {
    const expectedDatapointValue = overriddenExpectedBehaviours.get(transactionTypeConfiguration)
      ?? expectedTMThresholdDatapoint?.selectedValue;

    if (
      !expectedDatapointValue
      || expectedDatapointValue.currency !== currency
    ) return undefined;

    return expectedDatapointValue;
  }, [
    overriddenExpectedBehaviours,
    transactionTypeConfiguration,
    expectedTMThresholdDatapoint?.selectedValue,
    currency,
  ]);

  const transformMonthlyValues = useCallback((monthlyVolume: number) => {
    const diff = DateTime.fromISO(to).diff(DateTime.fromISO(to).minus({ days: windowSize ?? 1 }), 'months');
    return monthlyVolume * diff.months;
  }, [to, windowSize]);

  useEffect(() => {
    if (!data || isEmpty(data)) return;

    const newSeries = data as ReactApexChart['props']['series'];

    const allYValues = data.flatMap((item) => item.data.map((datapoint) => datapoint.y));

    if (showExpectedBehavior && expectedInfo) {
      if (isNumber(expectedInfo.incoming?.monthlyVolume)) {
        allYValues?.push(transformMonthlyValues(expectedInfo.incoming.monthlyVolume));
      } if (isNumber(expectedInfo.outgoing?.monthlyVolume)) {
        allYValues?.push(transformMonthlyValues(-expectedInfo.outgoing.monthlyVolume));
      } if (isNumber(expectedInfo.process?.monthlyVolume)) {
        allYValues?.push(transformMonthlyValues(expectedInfo.process.monthlyVolume));
      }
    }
    const minimumY = Math.min(...allYValues);
    const maximumY = Math.max(...allYValues);

    const newOptions = {
      colors: ['#388e3c', '#880808', '#FEB019', theme.palette.info.main],
      chart: {
        height: 400,
        events: {
          markerClick: onClickGraph,
        },
        type: 'line',
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: true,
        },
      },
      annotations: {
        yaxis: showExpectedBehavior && expectedInfo ? [
          ...(isNumber(expectedInfo.incoming?.monthlyVolume) ? [{
            y: transformMonthlyValues(expectedInfo.incoming.monthlyVolume),
            strokeDashArray: 5,
            borderWidth: 2,
            borderColor: '#388e3c',
            label: {
              borderColor: '#388e3c',
              text: `Expected rolling incoming: ${round2Digits(transformMonthlyValues(expectedInfo.incoming.monthlyVolume))} ${currency}`,
              style: {
                background: '#388e3c59',
              },
            },
          }] : []),
          ...(isNumber(expectedInfo.outgoing?.monthlyVolume) ? [{
            y: transformMonthlyValues(-expectedInfo.outgoing.monthlyVolume),
            strokeDashArray: 5,
            borderWidth: 2,
            borderColor: '#880808',
            label: {
              borderColor: '#880808',
              text: `Expected rolling outgoing: ${round2Digits(transformMonthlyValues(expectedInfo.outgoing.monthlyVolume))} ${currency}`,
              offsetY: 20,
              style: {
                background: '#88080859',
              },
            },
          }] : []),
          ...(isNumber(expectedInfo.total?.monthlyVolume) ? [{
            y: transformMonthlyValues(expectedInfo.total.monthlyVolume),
            strokeDashArray: 5,
            borderWidth: 2,
            borderColor: '#FEB019',
            label: {
              borderColor: '#FEB019',
              text: `Expected rolling total: ${round2Digits(transformMonthlyValues(expectedInfo.total.monthlyVolume))} ${currency}`,
              offsetY: 20,
              style: {
                background: '#FEB01959',
              },
            },
          }] : []),
          ...(isNumber(expectedInfo.process?.monthlyVolume) ? [{
            y: transformMonthlyValues(expectedInfo.process.monthlyVolume),
            strokeDashArray: 5,
            borderWidth: 2,
            borderColor: theme.palette.info.main,
            label: {
              borderColor: theme.palette.info.main,
              text: `Expected rolling processing: : ${round2Digits(transformMonthlyValues(expectedInfo.process.monthlyVolume))} ${currency}`,
              position: 'left',
              textAnchor: 'start',
              style: {
                background: `${theme.palette.info.main}59`,
              },
            },
          }] : []),
        ] : [],
      },
      stroke: {
        curve: 'smooth',
        width: [2, 2, 2],
      },
      fill: {
        type: ['solid', 'solid', 'solid'],
        opacity: [0.35, 0.35, 0.35],
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        decimalsInFloat: 0,
        min: minimumY,
        max: maximumY,
        forceNiceScale: true,
      },
      tooltip: {
        y: {
          formatter(value: number, {
            seriesIndex, dataPointIndex, w,
          }: {
            seriesIndex: number,
            dataPointIndex: number,
            w: RaRecord
          }) {
            const {
              y,
              currency: curr,
              frequency,
            } = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            if (!isNil(y)) {
              return `${y.toFixed(0)} ${curr} (${frequency})`;
            }
            return 'NaN';
          },
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          rotate: -90,
        },
      },
    } as ReactApexChart['props']['options']; // satisfies ReactApexChart['props']['options'] when ts is upgraded;

    setHasData(true);
    setOptions(newOptions);
    setSeries(newSeries);
  }, [currency, data, expectedInfo, onClickGraph, showExpectedBehavior, transformMonthlyValues]);

  return {
    hasData,
    options,
    series,
  };
};

const RollingSum = ({
  params,
  setParams,
  filter,
}: {
  params: Params,
  setParams: React.Dispatch<React.SetStateAction<Params>>
  filter: {
    from: string;
    to: string;
    currency: string;
    entityId: string;
    entityType: EntityTypes;
    requestType: typeof requestTypes.TRANSACTION;
    transactionTypeConfiguration?: string;
  }
}) => {
  const [selectedDate, setSelectedDate] = useState<string>(DateTime.now().toISODate()!);
  const debouncedWindowSize = useDebounce(params.rollingSumWindow, 400);
  const { open, closeDialog, openDialog } = useDialogStatus();
  const notify = useNotify();

  const { data, isLoading } = useGetList<{
    id: string;
    type: string;
    name: string;
    data: {
      x: string;
      y: number;
      frequency: number;
      currency: string;
    }[]
  }>(
    'requests/graph/rolling-sum',
    {
      filter: { ...filter, windowSize: debouncedWindowSize },
    },
    {
      enabled: isNumber(params.rollingSumWindow),
      onError: (e) => {
        notify(e instanceof HttpError && e.status === 400 ? e.message : 'Cannot fetch transaction stats', { type: 'error' });
      },
    },
  );

  const onClickGraph = useCallback<ApexChartOnClickHandler<{
    id: Identifier;
    x: string;
    y: number;
    frequency: number;
    currency: string;
  }>>((
    _event,
    _chartContext,
    config,
  ) => {
    const dataPoint = config?.w?.config?.series[2]?.data[config?.dataPointIndex];
    setSelectedDate(dataPoint?.x);
    openDialog();
  }, [openDialog]);

  const { hasData, options, series } = useGetGraphData(
    data,
    onClickGraph,
    filter,
    debouncedWindowSize,
  );

  const onSubmit = useCallback((values: { rollingSumWindow: number }) => {
    setParams((previousParams) => ({
      ...previousParams,
      rollingSumWindow: values.rollingSumWindow,
    }));
  }, [setParams]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardHeader
            title="Rolling Sum"
            action={(
              <Form values={params}>
                <Box gap="1rem" display="flex" flexDirection="row">
                  <NumberInput
                    label="Window"
                    source="rollingSumWindow"
                    validate={[required(), minValue(1)]}
                  />
                  <SubmitOnChange onSubmit={onSubmit} />
                </Box>
              </Form>
            )}
          />
          <Divider />
          {hasData && <ReactApexChart options={options} series={series} type="line" height={600} />}
          {!hasData && !isLoading && <NoResults variant="h4" />}
          {!hasData && isLoading && (
            <Box display="flex" alignItems="center" justifyContent="center" margin={4}>
              <CircularProgress />
            </Box>
          )}
        </Card>
      </Grid>
      <DetailedTransactionTable
        open={open}
        closeDialog={closeDialog}
        from={selectedDate}
        to={selectedDate}
        currency={filter.currency}
        entityId={filter.entityId}
        entityType={filter.entityType}
        transactionTypeConfiguration={filter.transactionTypeConfiguration}
      />
    </Grid>
  );
};

export default RollingSum;
