import React, { useEffect } from 'react';

import {
  required,
  TextInput,
} from 'react-admin';

import {
  Grid,
} from '@mui/material';

import { useFormContext } from 'react-hook-form';
import useMember from '../../../hooks/useMember';

const ReportingPerson = () => {
  const { setValue } = useFormContext();
  const { member } = useMember();

  useEffect(() => {
    if (member) {
      setValue('report.reporting_person', {
        first_name: member.firstName,
        last_name: member.lastName,
        residence: member.residence,
        email: member.email,
        employer_name: member.accountName,
        occupation: member.occupation,
        birthdate: member.birthDate,
        ssn: member.swedishSocialSecurityNumber,
        passport_number: member.foreignId,
        passport_country: member.foreignIdCountry,
      }, { shouldValidate: true });
    }
  }, [member, setValue]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextInput fullWidth disabled label="First name" source="report.reporting_person.first_name" validate={[required()]} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextInput fullWidth disabled label="Last name" source="report.reporting_person.last_name" validate={[required()]} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextInput fullWidth disabled label="Email" source="report.reporting_person.email" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextInput fullWidth label="Employer Name" source="report.reporting_person.employer_name" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextInput fullWidth label="Role" source="report.reporting_person.occupation" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextInput fullWidth label="Birth date" source="report.reporting_person.birthdate" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextInput fullWidth label="SSN" source="report.reporting_person.ssn" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextInput fullWidth label="Residence" source="report.reporting_person.residence" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextInput fullWidth label="Foreign ID" source="report.reporting_person.passport_number" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextInput fullWidth label="Foreign ID country" source="report.reporting_person.passport_country" />
      </Grid>
    </Grid>
  );
};

export default ReportingPerson;
