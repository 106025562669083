import React from 'react';
import { SelectField, TextField, useRecordContext } from 'react-admin';
import { get } from 'lodash';

import Answer from '../Answer';

const ValueField = ({
  extraParameters,
}: {
  extraParameters?: {
    label?: string,
    inputLabel?: string,
    id?: string,
    options?: {
      id: string;
      label: string;
    }[]
    multiple?: boolean;
  }
}) => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (extraParameters?.multiple) {
    return (
      <TextField
        record={{ value: value?.join(', ') }}
        sx={sx}
        source="value"
        emptyText="-"
        fontWeight="bold"
      />
    );
  }

  return extraParameters?.options ? (
    <SelectField
      choices={extraParameters?.options.map(({ id, label }) => ({ id, name: label }))}
      record={{ value }}
      sx={sx}
      source="value"
      emptyText="-"
      fontWeight="bold"
    />
  ) : (
    <TextField
      record={{ value }}
      sx={sx}
      source="value"
      emptyText="-"
      fontWeight="bold"
    />
  );
};

const Custom = ({ extraParameters }: {
  extraParameters?: {
    label?: string,
    inputLabel?: string,
    id?: string,
    options?: {
      id: string;
      label: string;
    }[]
    multiple?: boolean;
  }
}) => {
  if (!extraParameters?.label) return null;
  if (!extraParameters.id) return null;

  return (
    <Answer
      label={extraParameters.label}
      source={`custom.${extraParameters.id}`}
      datapoint={null}
    >
      <ValueField extraParameters={extraParameters} />
    </Answer>
  );
};

Custom.ValueField = ValueField;

export default Custom;
