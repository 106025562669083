import React, { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { get } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Cancel from '@mui/icons-material/Cancel';
import Radio from '@mui/material/Radio';

import {
  DatagridConfigurable,
  HttpError,
  SelectColumnsButton,
  useDataProvider,
  useNotify,
  useRecordContext,
  useStore,
  useResourceContext,
  BooleanInput,
  RecordContextProvider,
} from 'react-admin';
import { useQueries } from 'react-query';

import Params from './Params';
import { requestTypes } from '../../constants/requestTypes';
import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import AddTransactionTypeDialogButton, { TransactionTypeConfiguration } from './AddTransactionTypeDialogButton';
import TransactionTypeConfigurationField from '../../customFields/TransactionTypeConfigurationField';
import useDialogStatus from '../../hooks/useDialogStatus';
import { EntityTypes, entityTypesFromResource } from '../../constants/entityTypes';
import CompareTransactionBehaviorField from './CompareTransactionBehaviorField';
import DatapointStatusField from './DatapointStatusField';
import ExpectedBehaviourFilter from './ExpectedBehaviourFilter';

const rowSx = () => ({
  whiteSpace: 'nowrap',
});

const useGetConfigurationSummaries = (
  typeConfigurations: (TransactionTypeConfiguration | undefined)[],
  filter: {
    from: string;
    to: string;
    currency: string;
    entityId: string;
    entityType: EntityTypes;
    requestType: typeof requestTypes.TRANSACTION;
  },
) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const queries = useQueries(typeConfigurations.map((configuration) => {
    const queryParams = new URLSearchParams();
    queryParams.append('from', filter.from);
    queryParams.append('to', filter.to);
    queryParams.append('currency', filter.currency);
    queryParams.append('entityId', filter.entityId);
    queryParams.append('entityType', filter.entityType);
    queryParams.append('requestType', filter.requestType);
    if (configuration) queryParams.append('transactionTypeConfiguration', JSON.stringify(configuration));

    const id = `?${queryParams.toString()}`;

    return {
      queryKey: ['requests/graph/stats', 'getOne', { id }],
      queryFn: async () => {
        const response = await dataProvider.getOne<{
          id: string;
          outgoing: {
            frequency: number;
            volume: number;
          };
          incoming: {
            frequency: number;
            volume: number;
          };
          processing: {
            frequency: number;
            volume: number;
          };
          total: {
            frequency: number;
            volume: number;
          };
        }>('requests/graph/stats', {
          id: `?${queryParams.toString()}`,
        });
        return {
          ...response.data,
          id,
        };
      },
      enabled: !!filter.from && !!filter.to && !!filter.currency,
      onError: (e: unknown) => {
        notify(e instanceof HttpError && e.status === 400 ? e.message : 'Cannot fetch transaction summary', { type: 'error' });
      },
    };
  }));

  return queries;
};

const DeleteRowButton = ({
  removeRow,
}: {
  removeRow: (typeConfigurationString: TransactionTypeConfiguration) => void,
  source: string;
  label: false;
}) => {
  const record = useRecordContext<{
    id: string;
    typeConfiguration?: TransactionTypeConfiguration;
  }>();

  const { open, openDialog, closeDialog } = useDialogStatus();

  const onClick = useCallback(() => {
    if (!record?.typeConfiguration) return;
    removeRow(record?.typeConfiguration);
  }, [record?.typeConfiguration, removeRow]);

  if (!record?.typeConfiguration) return null;

  return (
    <>
      <IconButton onClick={openDialog} size="small">
        <RemoveCircleOutlineIcon color="background" />
      </IconButton>
      <Dialog scroll="body" open={open} onClose={closeDialog}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            Delete row
            <IconButton onClick={closeDialog}>
              <Cancel />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the row?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} variant="text">Cancel</Button>
          <Button onClick={onClick} variant="text">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const SelectRowRadioButton = ({
  source,
}: {
  source?: string;
  label: string;
}) => {
  const record = useRecordContext();
  const value = get(record, source ?? 'typeConfiguration');

  const { setValue } = useFormContext();
  const formValue = useWatch({ name: 'transactionTypeConfiguration' });

  const stringifiedValue = JSON.stringify(value);

  const onChange = useCallback(() => {
    setValue('transactionTypeConfiguration', stringifiedValue);
  }, [setValue, stringifiedValue]);

  return (
    <Radio
      checked={stringifiedValue === formValue}
      value={stringifiedValue}
      onChange={onChange}
    />
  );
};

const Summary = ({
  params,
  filter,
}: {
  params: Params;
  filter: {
    from: string;
    to: string;
    currency: string;
    entityId: string;
    entityType: EntityTypes;
    requestType: typeof requestTypes.TRANSACTION;
  }
}) => {
  const [
    typeConfigurations,
    setTypeConfigurations,
  ] = useStore<(TransactionTypeConfiguration | undefined)[]>('transactionTable', [undefined]);

  const [
    showExpectedBehavior,
    setShowExpectedBehavior,
  ] = useStore('transactionShowExpectedBehavior', false);

  const onChangeShowExpected = useCallback(() => {
    setShowExpectedBehavior((currentValue) => !currentValue);
  }, [setShowExpectedBehavior]);

  const results = useGetConfigurationSummaries(typeConfigurations, filter);

  const resource = useResourceContext();
  const entityRecord = useRecordContext<{ id: string }>();

  const entityType = entityTypesFromResource[resource];

  const record = useMemo(() => results.map((result, index) => ({
    ...result.data,
    netVolume: result.data ? result.data.incoming.volume - result.data.outgoing.volume : undefined,
    typeConfiguration: typeConfigurations[index],
  })), [results, typeConfigurations]);

  const diff = DateTime.fromISO(filter.to).diff(DateTime.fromISO(filter.from), 'months');
  const transformMonthlyStat = useCallback((value: number) => value * diff.months, [diff.months]);

  const addRow = useCallback((typeConfiguration?: TransactionTypeConfiguration) => {
    setTypeConfigurations((currentValue) => [...currentValue, typeConfiguration]);
  }, [setTypeConfigurations]);

  const removeRow = useCallback((typeConfiguration: TransactionTypeConfiguration) => {
    setTypeConfigurations((currentValue) => currentValue.filter((configuration) => (
      !configuration
      || JSON.stringify(configuration) !== JSON.stringify(typeConfiguration)
    )));
  }, [setTypeConfigurations]);

  const isLoading = results.some((result) => result.isLoading);
  if (!entityRecord) return null;

  return (
    <Grid container spacing={6} marginBottom={8}>
      <Grid item xs={12} md={12}>
        <Box display="flex" width="100%" justifyContent="flex-end" alignItems="center" gap={2}>
          {showExpectedBehavior && (
            <ExpectedBehaviourFilter
              entityId={entityRecord.id}
              entityType={entityType}
            />
          )}
          <RecordContextProvider value={{ showExpectedBehavior }}>
            <BooleanInput
              source="showExpectedBehavior"
              onChange={onChangeShowExpected}
              helperText={false}
              sx={{ '& .MuiFormControlLabel-root': { marginRight: 0 } }}
            />
          </RecordContextProvider>
          <SelectColumnsButton preferenceKey="transaction-summary-table" />
        </Box>
        <Card variant="outlined" sx={{ marginY: 1 }}>
          <DatagridConfigurable
            resource="data-points"
            data={record}
            total={record.length}
            isLoading={isLoading}
            bulkActionButtons={false}
            sort={{ field: '_id', order: 'DESC' }}
            rowSx={rowSx}
            sx={boldDataGridStyle}
            preferenceKey="transaction-summary-table"
          >
            <SelectRowRadioButton label="Show in graph" />
            <TransactionTypeConfigurationField source="typeConfiguration" label="Transaction type" />
            {showExpectedBehavior && <DatapointStatusField entityId={entityRecord.id} entityType={entityType} label="Conflict status" />}
            <CompareTransactionBehaviorField
              source="incoming.volume"
              options={{ style: 'currency', currency: params.currency, currencyDisplay: 'code' }}
              comparedSource="incoming.monthlyVolume"
              comparedTransform={transformMonthlyStat}
              showExpectedBehavior={showExpectedBehavior}
              entityId={entityRecord.id}
              entityType={entityType}
              currency={params.currency}
              textAlign="right"
            />
            <CompareTransactionBehaviorField
              source="outgoing.volume"
              options={{ style: 'currency', currency: params.currency, currencyDisplay: 'code' }}
              comparedSource="outgoing.monthlyVolume"
              comparedTransform={transformMonthlyStat}
              showExpectedBehavior={showExpectedBehavior}
              entityId={entityRecord.id}
              entityType={entityType}
              currency={params.currency}
              textAlign="right"
            />
            <CompareTransactionBehaviorField
              source="processing.volume"
              options={{ style: 'currency', currency: params.currency, currencyDisplay: 'code' }}
              comparedSource="processing.monthlyVolume"
              comparedTransform={transformMonthlyStat}
              showExpectedBehavior={showExpectedBehavior}
              entityId={entityRecord.id}
              entityType={entityType}
              currency={params.currency}
              textAlign="right"
            />
            <CompareTransactionBehaviorField
              source="total.volume"
              options={{ style: 'currency', currency: params.currency, currencyDisplay: 'code' }}
              comparedSource="total.monthlyVolume"
              comparedTransform={transformMonthlyStat}
              showExpectedBehavior={showExpectedBehavior}
              entityId={entityRecord.id}
              entityType={entityType}
              currency={params.currency}
              textAlign="right"
            />
            <CompareTransactionBehaviorField
              source="netVolume"
              options={{ style: 'currency', currency: params.currency, currencyDisplay: 'code' }}
              comparedSource="netMonthlyVolume"
              comparedTransform={transformMonthlyStat}
              showExpectedBehavior={showExpectedBehavior}
              entityId={entityRecord.id}
              entityType={entityType}
              currency={params.currency}
              textAlign="right"
            />
            <CompareTransactionBehaviorField
              source="incoming.frequency"
              label="Number incoming transactions"
              comparedSource="incoming.monthlyFrequency"
              comparedTransform={transformMonthlyStat}
              showExpectedBehavior={showExpectedBehavior}
              entityId={entityRecord.id}
              entityType={entityType}
              currency={params.currency}
              textAlign="right"
            />
            <CompareTransactionBehaviorField
              source="outgoing.frequency"
              label="Number outgoing transactions"
              comparedSource="outgoing.monthlyFrequency"
              comparedTransform={transformMonthlyStat}
              showExpectedBehavior={showExpectedBehavior}
              entityId={entityRecord.id}
              entityType={entityType}
              currency={params.currency}
              textAlign="right"
            />
            <CompareTransactionBehaviorField
              source="processing.frequency"
              label="Number processing transactions"
              comparedSource="processing.monthlyFrequency"
              comparedTransform={transformMonthlyStat}
              showExpectedBehavior={showExpectedBehavior}
              entityId={entityRecord.id}
              entityType={entityType}
              currency={params.currency}
              textAlign="right"
            />
            <CompareTransactionBehaviorField
              source="total.frequency"
              label="Total number transactions"
              comparedSource="total.monthlyFrequency"
              comparedTransform={transformMonthlyStat}
              showExpectedBehavior={showExpectedBehavior}
              entityId={entityRecord.id}
              entityType={entityType}
              currency={params.currency}
              textAlign="right"
            />
            <DeleteRowButton source="removeRow" removeRow={removeRow} label={false} />
          </DatagridConfigurable>
        </Card>
        <Box display="flex" width="100%" justifyContent="flex-start">
          <AddTransactionTypeDialogButton onConfirm={addRow} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Summary;
