import React, { useEffect, useRef, useState } from 'react';

import {
  Handle,
  NodeProps,
  Position,
  useNodeId,
} from 'reactflow';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { NodeData } from './utils';

const Node = ({
  selected,
  data,
  Icon,
}: {
  selected: boolean;
  data: NodeProps<NodeData>['data'];
  Icon: React.ElementType
}) => {
  const { id } = useParams();
  const nodeId = useNodeId();

  const ref = useRef<HTMLDivElement>(null);
  const [, setIsRendered] = useState(false);
  useEffect(() => {
    setIsRendered(true);
  }, []);

  if (!data) return null;

  const isRoot = data.isRoot || id === nodeId;

  const sx = selected ? {
    borderColor: 'primary.main',
  } : {};

  const primary = isRoot ? {
    backgroundColor: 'primary.main',
    color: 'white',
  } : {};

  return (
    <Box position="relative">
      <Handle type="target" position={Position.Top} draggable={false} isConnectable={false} />
      <Card
        variant="outlined"
        sx={{
          margin: 0,
          padding: 0,
          ...sx,
          ...primary,
          cursor: 'pointer',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          justifyContent="center"
          padding={1}
          width={60}
          height={60}
        >
          <Icon sx={{ fontSize: '3rem' }} color={isRoot ? 'inherit' : 'primary'} fontSize="large" />
        </Box>
      </Card>
      <Paper
        ref={ref}
        variant="elevation"
        sx={{
          position: 'absolute',
          backgroundColor: 'rgba(97, 97, 97, 0.92)',
          color: 'rgb(255, 255, 255)',
          padding: '4px 8px',
          top: 'calc(100% + 4px)',
          width: 'max-content',
          left: `calc(50% - ${(ref.current?.clientWidth ?? 0) / 2}px)`,
        }}
      >
        <Typography fontSize="0.6875rem">{data.label}</Typography>
      </Paper>
      <Handle type="source" position={Position.Bottom} draggable={false} isConnectable={false} />
    </Box>
  );
};

export default Node;
