import React, { useCallback } from 'react';

import {
  Box,
} from '@mui/material';
import { Form, TextInput } from 'react-admin';
import SubmitOnChange from '../../../../utils/SubmitOnChange';

export interface FilterType {
  registrationNumber?: string;
}

const Filter = ({
  filter,
  setFilter,
}: {
  filter?: FilterType,
  setFilter: (x: (prev?: FilterType) => FilterType) => void
}) => {
  const onSubmit = useCallback((data: FilterType) => {
    setFilter((prev) => ({ ...prev, ...data }));
  }, [setFilter]);

  return (
    <Box>
      <Form record={filter}>
        <SubmitOnChange onSubmit={onSubmit} throttleMs={200} />
        <TextInput
          source="registrationNumber"
          size="small"
          label="Registration number"
        />
      </Form>
    </Box>
  );
};

export default Filter;
