import React from 'react';

import { TextField, useRecordContext } from 'react-admin';

import { get, isEmpty } from 'lodash';

import { Typography } from '@mui/material';

import Answer from '../Answer';

import { QuestionElementTypes } from '../../../../constants/questionElementTypes';
import conflictFields from '../../../../constants/conflictFields';

export type PurposeFormAnswer = {
  options: string[];
}

const ValueField = () => {
  const record = useRecordContext<{ value: PurposeFormAnswer }>();

  const value = get(record, 'value.options');
  const sx = get(record, 'sx', {});

  if (!value || isEmpty(value)) return <Typography>-</Typography>;

  return <TextField record={{ value: value.join(', ') }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const Purpose = ({
  extraParameters,
}: {
  extraParameters?: {
    label?: string,
    options?: { id: string, label: string }[]
    type?: QuestionElementTypes
  }
}) => (
  <Answer
    label={extraParameters?.label ?? ''}
    source="purpose"
    datapoint={conflictFields.PURPOSE}
  >
    <ValueField />
  </Answer>
);

Purpose.ValueField = ValueField;

export default Purpose;
