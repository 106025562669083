import { useEffect } from 'react';
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';

const SubmitOnChange = <T extends FieldValues>({
  onSubmit,
  throttleMs = 0,
}: {
  onSubmit: SubmitHandler<T>;
  throttleMs?: number;
}) => {
  const { handleSubmit, watch, trigger } = useFormContext<T>();

  useEffect(() => {
    let timeoutId: number | null = null;

    const subscription = watch(async () => {
      if (throttleMs > 0 && timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(async () => {
        const isFormValid = await trigger();
        if (isFormValid) handleSubmit(onSubmit)();
        timeoutId = null;
      }, throttleMs);
    });

    return () => {
      subscription.unsubscribe();
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [handleSubmit, onSubmit, throttleMs, trigger, watch]);

  return null;
};

export default SubmitOnChange;
