import React from 'react';

import {
  required,
  SelectInput,
  TextInput,
} from 'react-admin';

import {
  Grid,
} from '@mui/material';

import { legalFormTypeChoices } from '../../../../../../constants/legalForms';
import languages from '../../../../../../constants/languages';
import { IBusiness } from '../../../../../../utilities/schemas/business';
import entityTypes from '../../../../../../constants/entityTypes';

export type EntityFormValues = {
  recordId: string,
  type: typeof entityTypes.BUSINESS,
  t_entity: {
    name: string,
    incorporation_number?: string,
    incorporation_legal_form?: string,
  }
}

export const prefillEntity = (
  record: IBusiness,
) => ({
  recordId: record.id,
  type: entityTypes.BUSINESS,
  t_entity: {
    name: record.name,
    incorporation_number: record.swedishOrganizationNumber,
    incorporation_legal_form: record.incorporationLegalForm,
  },
});

const Entity = () => (
  <Grid container spacing={6}>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput fullWidth source="t_entity.name" label="Name" validate={required()} />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput fullWidth source="t_entity.incorporation_number" label="Incorporation Number" />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <SelectInput
        choices={legalFormTypeChoices(languages.EN)}
        source="t_entity.incorporation_legal_form"
        label="Incorporation Legal Form"
      />
    </Grid>
  </Grid>
);

export default Entity;
