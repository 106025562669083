import React from 'react';

import {
  useRecordContext, SelectField, TextField,
} from 'react-admin';

import { get } from 'lodash';

import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import { legalFormTypeChoices } from '../../../../constants/legalForms';
import conflictFields from '../../../../constants/conflictFields';

const CONTENT = {
  [languages.SV]: 'Bolagsform',
  [languages.EN]: 'Legal form of business',
};

export type LegalFormFormAnswer = {
  options: string;
  other?: string;
}

const ValueField = ({
  language,
}: {
  language?: Languages,
}) => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (!language) return null;

  if (value?.options === 'other') {
    return <TextField sx={sx} source="value.other" emptyText="-" fontWeight="bold" />;
  }

  return <SelectField choices={legalFormTypeChoices(language)} record={{ value }} sx={sx} source="value.options" emptyText="-" fontWeight="bold" />;
};

const LegalForm = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={CONTENT[language]}
      source="legalForm"
      datapoint={conflictFields.LEGAL_FORM}
    >
      <ValueField language={language} />
    </Answer>
  );
};

LegalForm.ValueField = ValueField;

export default LegalForm;
