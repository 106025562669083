import React from 'react';

import { TextField, useRecordContext } from 'react-admin';

import { get, isEmpty } from 'lodash';

import { Typography } from '@mui/material';

import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import conflictFields from '../../../../constants/conflictFields';

const VALUE_LABEL = {
  [languages.SV]: 'Företagets senaste årsomsättning.',
  [languages.EN]: 'Company’s most recent yearly turnover',
};

const ValueField = () => {
  const record = useRecordContext();

  const valueObj = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (isEmpty(valueObj)) return <Typography>-</Typography>;

  const value = `${valueObj.value} ${valueObj.currency}`;

  return <TextField record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const PreviousTurnover = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={VALUE_LABEL[language]}
      source="previousTurnover"
      datapoint={conflictFields.PREVIOUS_TURNOVER}
    >
      <ValueField />
    </Answer>
  );
};

PreviousTurnover.ValueField = ValueField;

export default PreviousTurnover;
