import { ValueOf } from '../../../utilities/types';

const ruleSubCategories = {
  INITIATOR: 'sub-initiator',
  RECIPIENT: 'sub-recipient',
  PROCESSOR: 'sub-processor',
  BUYER: 'sub-buyer',
  SELLER: 'sub-seller',
  KYC_INDIVIDUAL: 'sub-kyc-individual',
  KYC_BUSINESS: 'sub-kyc-business',
  CAR_KYC: 'sub-car-kyc',
  CAR_PURCHASE: 'sub-car-purchase',
  TRANSACTION: 'sub-transaction',
  TEST: 'sub-test',
} as const;

export const ruleSubCategoriesChoices = [
  { id: ruleSubCategories.INITIATOR, name: 'Initiator' },
  { id: ruleSubCategories.RECIPIENT, name: 'Recipient' },
  { id: ruleSubCategories.PROCESSOR, name: 'Processor' },
  { id: ruleSubCategories.BUYER, name: 'Buyer' },
  { id: ruleSubCategories.SELLER, name: 'Seller' },
  { id: ruleSubCategories.KYC_INDIVIDUAL, name: 'Individual' },
  { id: ruleSubCategories.KYC_BUSINESS, name: 'Business' },
  { id: ruleSubCategories.CAR_KYC, name: 'KYC' },
  { id: ruleSubCategories.CAR_PURCHASE, name: 'Purchase' },
  { id: ruleSubCategories.TRANSACTION, name: 'Transaction' },
  { id: ruleSubCategories.TEST, name: 'Test' },
];

export type RuleSubCategories = ValueOf<typeof ruleSubCategories>;

export default ruleSubCategories;
