import { ValueOf } from '../../../utilities/types';

const ruleCategories = {
  TRANSACTION: 'transaction',
  KYC: 'kyc',
  CAR: 'car',
  TEST: 'test',
} as const;

export const ruleCategoriesChoices = [
  { id: ruleCategories.TRANSACTION, name: 'Transaction' },
  { id: ruleCategories.KYC, name: 'KYC' },
  { id: ruleCategories.CAR, name: 'Car' },
  { id: ruleCategories.TEST, name: 'Test' },
];

export type RuleCategories = ValueOf<typeof ruleCategories>;

export default ruleCategories;
