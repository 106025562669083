import React, { useState, useCallback } from 'react';
import {
  Form,
  required,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Recommendations } from '../constants/recommendations';
import useDialogStatus from '../hooks/useDialogStatus';

const UpdateRecommendationField = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext();
  const {
    open,
    openDialog,
    closeDialog,
  } = useDialogStatus();
  const [recommendation, setRecommendation] = useState<Recommendations>();
  const [updateType, setUpdateType] = useState<string>();

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    openDialog();
    setUpdateType(event.currentTarget.value);
    if (event.currentTarget.value === 'Block') setRecommendation('block');
    if (event.currentTarget.value === 'Proceed') setRecommendation('proceed');
  }, [openDialog]);

  const [update] = useUpdate();

  const handleSubmit = useCallback((data: { comment?: string }) => {
    update(
      'requests',
      {
        id: `${record?.id}/recommendation`,
        data: {
          recommendation,
          comment: data.comment,
        },
      },
      {
        onSuccess: () => {
          closeDialog();
          refresh();
        },
        onError: () => {
          notify('Could not update recommendation', { type: 'error' });
        },
      },
    );
  }, [closeDialog, notify, recommendation, record?.id, refresh, update]);

  if (record?.recommendation !== 'review') return null;

  return (
    <Box display="flex">
      <Box display="flex" marginRight={2}>
        <Button
          style={{ backgroundColor: '#880808', color: '#FFFFFF' }}
          variant="contained"
          onClick={handleClick}
          value="Block"
        >
          Block
        </Button>
      </Box>
      <Box display="flex" marginRight={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          value="Proceed"
        >
          Proceed
        </Button>
      </Box>

      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xs">
        <Form onSubmit={handleSubmit}>
          <DialogTitle>{updateType}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`${updateType} `}
              by entering a comment here.
            </DialogContentText>
            <TextInput
              margin="dense"
              source="comment"
              multiline
              validate={required()}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              {updateType}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </Box>
  );
};

UpdateRecommendationField.defaultProps = {
  addLabel: false,
};
export default UpdateRecommendationField;
