import ruleNames, { RuleNames } from '../../../constants/ruleNames';
import ruleSubCategories, { RuleSubCategories } from '../categories/ruleSubCategories';

const ruleAssociations: Record<RuleNames, RuleSubCategories[]> = {
  [ruleNames.ADDITIONAL_DELIVERY_ADDRESS]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.AFFORDABILITY]: [
    ruleSubCategories.INITIATOR,
    ruleSubCategories.RECIPIENT,
    ruleSubCategories.BUYER,
    ruleSubCategories.SELLER,
  ],
  [ruleNames.AGE_THRESHOLD]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.AMOUNT_THRESHOLD]: [
    ruleSubCategories.CAR_PURCHASE,
    ruleSubCategories.TRANSACTION,
  ],
  [ruleNames.ANOMALY_DETECTION_INCOMING_AVERAGE]: [
    ruleSubCategories.RECIPIENT,
    ruleSubCategories.SELLER,
  ],
  [ruleNames.ANOMALY_DETECTION_INCOMING_FREQUENCY]: [
    ruleSubCategories.RECIPIENT,
    ruleSubCategories.SELLER,
  ],
  [ruleNames.ANOMALY_DETECTION_INCOMING_SINGLE_AMOUNT]: [
    ruleSubCategories.RECIPIENT,
    ruleSubCategories.SELLER,
  ],
  [ruleNames.ANOMALY_DETECTION_INCOMING_VOLUME_ROLLING_SUM]: [
    ruleSubCategories.RECIPIENT,
    ruleSubCategories.SELLER,
  ],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_AVERAGE]: [
    ruleSubCategories.INITIATOR,
    ruleSubCategories.BUYER,
  ],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_FREQUENCY]: [
    ruleSubCategories.INITIATOR,
    ruleSubCategories.BUYER,
  ],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_SINGLE_AMOUNT]: [
    ruleSubCategories.INITIATOR,
    ruleSubCategories.BUYER,
  ],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_VOLUME_ROLLING_SUM]: [
    ruleSubCategories.INITIATOR,
    ruleSubCategories.BUYER,
  ],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_AVERAGE]: [
    ruleSubCategories.PROCESSOR,
  ],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_FREQUENCY]: [
    ruleSubCategories.PROCESSOR,
  ],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_SINGLE_AMOUNT]: [
    ruleSubCategories.PROCESSOR,
  ],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_VOLUME_ROLLING_SUM]: [
    ruleSubCategories.PROCESSOR,
  ],
  [ruleNames.ANOMALY_DETECTION_TOTAL_AVERAGE]: [
    ruleSubCategories.RECIPIENT,
    ruleSubCategories.INITIATOR,
    ruleSubCategories.PROCESSOR,
    ruleSubCategories.BUYER,
    ruleSubCategories.SELLER,
  ],
  [ruleNames.ANOMALY_DETECTION_TOTAL_FREQUENCY]: [
    ruleSubCategories.RECIPIENT,
    ruleSubCategories.INITIATOR,
    ruleSubCategories.PROCESSOR,
    ruleSubCategories.BUYER,
    ruleSubCategories.SELLER,
  ],
  [ruleNames.ANOMALY_DETECTION_TOTAL_SINGLE_AMOUNT]: [
    ruleSubCategories.RECIPIENT,
    ruleSubCategories.INITIATOR,
    ruleSubCategories.PROCESSOR,
    ruleSubCategories.BUYER,
    ruleSubCategories.SELLER,
  ],
  [ruleNames.ANOMALY_DETECTION_TOTAL_VOLUME_ROLLING_SUM]: [
    ruleSubCategories.RECIPIENT,
    ruleSubCategories.INITIATOR,
    ruleSubCategories.PROCESSOR,
    ruleSubCategories.BUYER,
    ruleSubCategories.SELLER,
  ],
  [ruleNames.ANOMALY_DETECTION]: [
    ruleSubCategories.INITIATOR,
    ruleSubCategories.RECIPIENT,
    ruleSubCategories.BUYER,
    ruleSubCategories.SELLER,
  ],
  [ruleNames.BUSINESS_ACTIVE]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.BUSINESS_AUDITOR]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.BUSINESS_DATA_CHANGED]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.BUSINESS_EQUITY_SHARE]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.BUSINESS_EQUITY]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.BUSINESS_FINANCIAL_STATEMENT_COMMENT]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.BUSINESS_FTAX]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS_SUM]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.BUSINESS_PAYMENT_REMARKS_SUM]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.BUSINESS_PAYMENT_REMARKS]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.BUSINESS_PROVIDER_WARNING]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.BUSINESS_SANCTION_LIST]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.BUSINESS_VAT]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.CAR_FREQ_HISTORICAL_OWNERS]: [
    ruleSubCategories.CAR_KYC,
  ],
  [ruleNames.CAR_GUARANTOR_RELATED]: [
    ruleSubCategories.CAR_PURCHASE,
  ],
  [ruleNames.CAR_IN_CIRCULATION]: [
    ruleSubCategories.CAR_KYC,
  ],
  [ruleNames.CAR_VALUATION]: [
    ruleSubCategories.CAR_PURCHASE,
  ],
  [ruleNames.CASH_FLOW]: [
    ruleSubCategories.INITIATOR,
    ruleSubCategories.RECIPIENT,
    ruleSubCategories.BUYER,
    ruleSubCategories.SELLER,
  ],
  [ruleNames.CATEGORIZATION_AMOUNT_THRESHOLD]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.CO_ADDRESS]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.COLLECTOR]: [
    ruleSubCategories.KYC_INDIVIDUAL,
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.CONNECTED_BUYER_SELLER]: [
    ruleSubCategories.CAR_PURCHASE,
  ],
  [ruleNames.CONNECTED_ENTITY_PEP]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.CONNECTED_ENTITY_SANCTION_LIST]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.COUNTRY_CITIZENSHIP_BLACKLIST]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.COUNTRY_RESIDENCE_BLACKLIST]: [
    ruleSubCategories.KYC_INDIVIDUAL,
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.COUNTRY_RESIDENCE_WHITELIST]: [
    ruleSubCategories.KYC_INDIVIDUAL,
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.EMPLOYMENT_TYPE]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.FOREIGN_ADDRESS]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.INCOMING_CATEGORIZATION_FREQUENCY]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.INCOMING_CATEGORIZATION_ROLLING_SUM]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.INCOMING_FREQUENCY]: [
    ruleSubCategories.RECIPIENT,
    ruleSubCategories.SELLER,
  ],
  [ruleNames.INCOMING_VOLUME_ROLLING_SUM]: [
    ruleSubCategories.RECIPIENT,
    ruleSubCategories.SELLER,
  ],
  [ruleNames.INDIVIDUAL_CRIMINAL_RECORD]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.INDIVIDUAL_PEP]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.INDIVIDUAL_SANCTION_LIST]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.IP_COUNTRY_BLACKLIST]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.KYC_VALID]: [
    ruleSubCategories.KYC_INDIVIDUAL,
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.LOW_INCOME]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.OUTGOING_CATEGORIZATION_FREQUENCY]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.OUTGOING_CATEGORIZATION_ROLLING_SUM]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.OUTGOING_FREQUENCY]: [
    ruleSubCategories.INITIATOR,
    ruleSubCategories.BUYER,
  ],
  [ruleNames.OUTGOING_VOLUME_ROLLING_SUM]: [
    ruleSubCategories.INITIATOR,
    ruleSubCategories.BUYER,
  ],
  [ruleNames.PROCESSING_FREQUENCY]: [
    ruleSubCategories.PROCESSOR,
  ],
  [ruleNames.PROCESSING_VOLUME_ROLLING_SUM]: [
    ruleSubCategories.PROCESSOR,
  ],
  [ruleNames.RELATED_INDIVIDUALS_CRIMINAL_RECORD]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.SELLER_OWNS_CAR]: [
    ruleSubCategories.CAR_PURCHASE,
    ruleSubCategories.SELLER,
  ],
  [ruleNames.SNI_CODE_BLACKLIST]: [
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleNames.SPAR_CHANGED]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.SOURCE_OF_FUNDS]: [
    ruleSubCategories.KYC_BUSINESS,
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.SOURCE_OF_INCOME]: [
    ruleSubCategories.KYC_BUSINESS,
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.SUSPICIOUS_FUNDS]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.TEST_BLOCK]: [
    ruleSubCategories.TEST,
  ],
  [ruleNames.TEST_REVIEW]: [
    ruleSubCategories.TEST,
  ],
  [ruleNames.TIME_WINDOW]: [
    ruleSubCategories.TRANSACTION,
    ruleSubCategories.CAR_PURCHASE,
  ],
  [ruleNames.TOTAL_CATEGORIZATION_FREQUENCY]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.TOTAL_CATEGORIZATION_ROLLING_SUM]: [
    ruleSubCategories.KYC_INDIVIDUAL,
  ],
  [ruleNames.TOTAL_FREQUENCY]: [
    ruleSubCategories.INITIATOR,
    ruleSubCategories.RECIPIENT,
    ruleSubCategories.PROCESSOR,
    ruleSubCategories.BUYER,
    ruleSubCategories.SELLER,
  ],
  [ruleNames.TOTAL_VOLUME_ROLLING_SUM]: [
    ruleSubCategories.INITIATOR,
    ruleSubCategories.RECIPIENT,
    ruleSubCategories.PROCESSOR,
    ruleSubCategories.BUYER,
    ruleSubCategories.SELLER,
  ],
  [ruleNames.ZIP_CODE_BLACKLIST]: [
    ruleSubCategories.KYC_INDIVIDUAL,
    ruleSubCategories.KYC_BUSINESS,
  ],
};

export default ruleAssociations;
