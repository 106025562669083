import React from 'react';

import {
  DateInput,
  required,
  SelectInput,
} from 'react-admin';

import {
  Grid,
} from '@mui/material';

import TextInput from '../../../../layout/inputFields/TextField';

import { requestTypes } from '../../../../../constants/requestTypes';
import { TransmodeTypes, transmodeTypesChoices } from '../../../../../constants/transmodeTypes';
import InfoPopover from '../../../../layout/InfoPopover';
import { ICarPurchase, ITransaction } from '../../../../request/types';
import TransactionPartyForm, { TransactionPartyFormValues } from './accordion/entity/TransactionPartyForm';
import GoodsAccordion, { FormWithGoodsValues } from './accordion/goods/GoodsAccordion';
import entityTypes from '../../../../../constants/entityTypes';
import { DeepPartial } from '../../../../../utilities/types';

export type RequestValues = {
  recordId: string,
  transactionnumber: string,
  amount_local: number,
  date_transaction: Date,
  teller?: string,
  transaction_location?: string,
  comment?: string,
  internal_ref_number?: string,
  transmode_code: TransmodeTypes,
  transmode_comment?: string,
  from: TransactionPartyFormValues,
  to: TransactionPartyFormValues,
} & FormWithGoodsValues;

const getFrom = (request: ITransaction | ICarPurchase) => {
  if (request.requestType === requestTypes.TRANSACTION) {
    return request.extraInformation.initiator;
  }
  return request.extraInformation.buyer;
};

const getTo = (request: ITransaction | ICarPurchase) => {
  if (request.requestType === requestTypes.TRANSACTION) {
    return request.extraInformation.recipient;
  }
  return request.extraInformation.seller;
};

export const prefillRequest = (
  request: ITransaction | ICarPurchase,
): DeepPartial<RequestValues> & { recordId: string } => {
  const from = getFrom(request);
  const to = getTo(request);
  const fromType = from?.type;
  const toType = to?.type;

  const goodsFields: DeepPartial<
  FormWithGoodsValues
  > = request.requestType === requestTypes.CAR_PURCHASE ? {
    includeGoods: true,
    goods_services: [{
      item: {
        id: request.extraInformation.car.id,
        item_type: 'FORDB',
      },
    }],
  } : {
    includeGoods: false,
    goods_services: [],
  };

  return {
    recordId: request.id,
    transactionnumber: request.id,
    amount_local: request.extraInformation.amount,
    date_transaction: request.extraInformation.date,
    teller: from?.ip,
    transaction_location: from?.electronicId,
    comment: request.extraInformation.textMessage,
    internal_ref_number: request.extraInformation.reference,
    transmode_code: request.extraInformation.transactionChannel,
    transmode_comment: request.extraInformation.transactionChannelProvider,
    from: {
      type: fromType === entityTypes.INDIVIDUAL || fromType === entityTypes.BUSINESS
        ? fromType : undefined,
      id: fromType === entityTypes.INDIVIDUAL || fromType === entityTypes.BUSINESS
        ? from?.id : undefined,
      isMyClient: false,
      country: from?.transactionCountry,
      foreign_currency: request.extraInformation.currency,
      funds_code: from?.transactionCarrierType,
      funds_comment: from?.transactionCarrierReference,
    },
    to: {
      type: toType === entityTypes.INDIVIDUAL || toType === entityTypes.BUSINESS
        ? toType : undefined,
      id: toType === entityTypes.INDIVIDUAL || toType === entityTypes.BUSINESS
        ? to?.id : undefined,
      isMyClient: false,
      country: to?.transactionCountry,
      foreign_currency: request.extraInformation.currency,
      funds_code: to?.transactionCarrierType,
      funds_comment: to?.transactionCarrierReference,
    },
    ...goodsFields,
  };
};

const RequestForm = () => (
  <Grid container spacing={6}>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput
        source="transactionnumber"
        label="Transaction Number"
        validate={required()}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput
        source="amount_local"
        label="Amount"
        validate={required()}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <DateInput
        source="date_transaction"
        label="Date"
        validate={required()}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput
        source="internal_ref_number"
        label="Reference"
        fullWidth
        popups={[<InfoPopover
          title="Reference"
          content="Refers to the regulation (EU) 2015/847 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL
            of 20 May 2015 on information accompanying transfers of funds and repealing Regulation (EC) No 1781/2006.
            If the transaction is not included in the regulation then state another reference which purpose is to
            trace the transaction, for example a reference for swish or an OCR message."
        />]}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput source="teller" label="IP" fullWidth />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput
        source="transaction_location"
        label="Electronic ID"
        fullWidth
        popups={[<InfoPopover
          title="Electronic ID"
          content="For transactions authorized trough BankId, the serial number of the certificate could be used."
        />]}

      />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <SelectInput
        choices={transmodeTypesChoices}
        source="transmode_code"
        label="Transaction channel"
        fullWidth
        validate={required()}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput
        source="transmode_comment"
        label="Transaction channel provider"
        fullWidth
        popups={[<InfoPopover
          title="Transaction channel provider"
          content="Provider of transaction channel for specific cases if the information is known.
            For `Money transfer` state the name of the organization that provided the money transfer.
            For `Gambling transactions` state the name of the organization that provided the game.
            For `Securities transactions` state the name of the organization that provided the transaction."
        />]}

      />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput
        source="comment"
        label="Text message"
        fullWidth
        popups={[<InfoPopover
          title="Text message"
          content="The text that the sender may have written regarding the transaction."
        />]}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <TransactionPartyForm
        direction="from"
        title="From"
      />
      <TransactionPartyForm
        direction="to"
        title="To"
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <GoodsAccordion />
    </Grid>
  </Grid>
);

export default RequestForm;
