import React, { useEffect } from 'react';

import {
  DateInput,
  Loading,
  required,
  TextInput,
  useGetOne,
} from 'react-admin';

import {
  Box,
  Grid,
} from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import RootFields, { TransactionPartyRootFormValues } from './RootFields';
import { IIndividual } from '../../../../../../../utilities/schemas/individual';

export type PersonFormValues = TransactionPartyRootFormValues & ({
  id?: string;
  isMyClient: true;
  person: {
    first_name: string;
    last_name: string;
    ssn?: string;
    birthdate: Date;
  };
} | {
  id?: string;
  isMyClient: false;
  person: {
    first_name: string;
    last_name: string;
    ssn?: string;
    birthdate?: Date;
  };
})

const Person = ({
  direction,
}: {
  direction: string
}) => {
  const defaultEntityId = useWatch({ name: `${direction}.id` });
  const { data, isLoading } = useGetOne<IIndividual>(
    'individuals',
    { id: defaultEntityId! },
    { enabled: !!defaultEntityId },
  );
  const isMyClient = useWatch({ name: `${direction}.isMyClient` });
  const entitySource = `${direction}.person`;
  const { resetField } = useFormContext();

  useEffect(() => {
    if (data) {
      resetField(entitySource, {
        defaultValue: {
          first_name: data.firstName,
          last_name: data.lastName,
          ssn: data.swedishSocialSecurityNumber,
          birthdate: data.birthDate,
        },
      });
    }
  }, [data, resetField, entitySource]);

  if (isLoading && !data) {
    return (
      <Box width="100%" height="100%" sx={{ transform: 'translate(0%, 100%)' }}>
        <Loading />
      </Box>
    );
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <RootFields direction={direction} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextInput fullWidth source={`${entitySource}.first_name`} label="First name" validate={required()} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextInput fullWidth source={`${entitySource}.last_name`} label="Last name" validate={required()} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextInput fullWidth source={`${entitySource}.ssn`} label="Swedish social security number" />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DateInput fullWidth source={`${entitySource}.birthdate`} label="Birth date" validate={isMyClient ? required() : undefined} />
      </Grid>
    </Grid>
  );
};

export default Person;
