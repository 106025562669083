import React, { Fragment } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';

import { get, isEmpty } from 'lodash';
import { Alpha3Code } from 'i18n-iso-countries';

import {
  TextInput,
  useRecordContext,
  TextField,
  SelectInput,
  SelectField,
  DateInput,
  DateField,
  required,
  RecordContextProvider,
} from 'react-admin';

import CircleContainer from '../../layout/CircleContainer';

import ArrayInput from './base/ArrayInput';

import { countryChoices } from '../../../constants/countries';

import cleaner from '../../../utilities/dataProvider/cleaner';

type BeneficialOwnersDatapointValue = {
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  residence: Alpha3Code;
  citizenship: {
    country: Alpha3Code;
  }[]
}[];

const BeneficialOwnerAccordionBody = ({ index }: { index: number }) => {
  const values = useRecordContext<BeneficialOwnersDatapointValue>();

  if (!values) return null;

  const record = values?.at(index);

  if (isEmpty(record)) return null;

  return (
    <Grid container spacing={4} padding={2}>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">First name</Typography>
          <TextField record={record} source="firstName" emptyText="-" />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">Last name</Typography>
          <TextField record={record} source="lastName" emptyText="-" />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">Date of birth</Typography>
          <DateField record={record} source="dateOfBirth" emptyText="-" />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">Residence</Typography>
          <TextField record={record} source="residence" emptyText="-" />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">Citizenship</Typography>
          <Box display="flex" gap={0}>
            {record?.citizenship?.map(
              (item, i: number) => (
                <Fragment key={item.country}>
                  <SelectField
                    key={item.country}
                    record={{ item }}
                    source="item.country"
                    choices={countryChoices('en')}
                  />
                  {i + 1 !== record?.citizenship?.length && <Typography mr={1}>,</Typography>}
                </Fragment>
              ),
            )}
            {isEmpty(record?.citizenship) && <Typography>-</Typography>}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export const BeneficialOwnersDatapointField = () => {
  const record = useRecordContext<{
    value: BeneficialOwnersDatapointValue
  }>();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (!value) return null;

  return (
    <Card
      variant="outlined"
      square
      sx={{
        m: 0,
        py: 0,
        width: '100%',
      }}
    >
      <Grid container bgcolor="background.paper">
        {value?.map((signatory, index) => (
          <Grid key={`signatory-${index + 1}`} item xs={12} md={12}>
            <Accordion sx={{ maxWidth: null, backgroundColor: 'transparent' }}>
              <AccordionSummary
                expandIcon={<ChevronRight />}
                sx={{
                  flexDirection: 'row-reverse',
                  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                    transform: 'rotate(90deg)',
                  },
                }}
              >
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    ml: 4,
                    ...sx,
                  }}
                  variant="h6"
                >
                  {`${signatory.firstName} ${signatory.lastName}`}
                </Typography>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <RecordContextProvider value={value}>
                  <BeneficialOwnerAccordionBody index={index} />
                </RecordContextProvider>
              </AccordionDetails>
            </Accordion>
            {index + 1 !== value.length && <Divider />}
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

const BeneficialOwnerDialogBody = () => (
  <Grid container columnSpacing={6}>
    <Grid item xs={12} md={6}>
      <TextInput fullWidth source="firstName" label="First name" />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextInput fullWidth source="lastName" label="Last name" />
    </Grid>
    <Grid item xs={12} md={6}>
      <DateInput fullWidth source="dateOfBirth" label="Date of birth" />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput choices={countryChoices('en')} fullWidth source="residence.0" label="Residence" />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput choices={countryChoices('en')} fullWidth source="citizenship[0].country" label="Citizenship" />
    </Grid>
  </Grid>
);

const ItemLabel = ({ value } : {value: Record<string, string>}) => {
  if (isEmpty(value)) return null;

  return (
    <Box display="flex" alignItems="center" gap={5}>
      <CircleContainer borderColor="transparent" p={0} bgcolor="primary.main" size={37}>
        <Typography color="background.paper" variant="h6">
          {value.firstName?.at(0) ?? ''}
        </Typography>
      </CircleContainer>
      <Typography
        variant="h6"
        color="primary.main"
      >
        {['firstName', 'lastName'].map((field) => value[field]).join(' ')}
      </Typography>
    </Box>
  );
};

const BeneficialOwners = () => (
  <Grid container spacing={10}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
        marginBottom={4}
      >
        Beneficial owners
      </Typography>
      <ArrayInput
        title="Add BeneficialOwner"
        itemNameSource="firstName"
        ItemLabel={ItemLabel}
        DialogBody={BeneficialOwnerDialogBody}
        AccordionBody={BeneficialOwnerAccordionBody}
        validate={required()}
        transformItemData={cleaner}
      />
    </Grid>
    <Grid item xs={12} md={12} justifyContent="center" display="flex">
      <TextInput
        fullWidth
        source="comment"
        multiline
        rows={4}
        helperText={false}
        placeholder="Please explain your decision."
      />
    </Grid>
  </Grid>
);

export default BeneficialOwners;
