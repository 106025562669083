import React, { useState, useCallback } from 'react';

import {
  Button,
  Box,
  Typography,
} from '@mui/material';

import {
  NumberInput,
  PasswordInput,
  TextField,
  TextInput,
  DateInput,
  Validator,
  NumberField,
  DateField,
  UpdateParams,
  UseUpdateOptions,
  UpdateMutationFunction,
  useRegisterMutationMiddleware,
  useRecordContext,
  BooleanField,
  BooleanInput,
} from 'react-admin';

import { useFormContext } from 'react-hook-form';

import CreateIcon from '@mui/icons-material/Create';
import CancelIcon from '@mui/icons-material/Cancel';
import { get } from 'lodash';
import ColorPicker, { ColorField } from '../components/layout/ColorPicker';

const typeFields = {
  text: TextField,
  number: NumberField,
  password: TextField,
  date: DateField,
  color: ColorField,
  boolean: BooleanField,
} as const;

const typeInputs = {
  text: TextInput,
  number: NumberInput,
  password: PasswordInput,
  date: DateInput,
  color: ColorPicker,
  boolean: BooleanInput,
} as const;

const EditField = ({
  source,
  label,
  showEdit,
  validate,
  type = 'text',
}: {
  source: string,
  label: string,
  showEdit: boolean,
  validate?: Validator | Validator[],
  type?: keyof typeof typeFields,
}) => {
  const [editable, setEditable] = useState(false);

  const { resetField } = useFormContext();
  const record = useRecordContext();
  const defaultValue = get(record, source);

  const handleChange = useCallback(() => {
    const newValueEditable = !editable;
    if (!newValueEditable) resetField(source, { defaultValue });
    setEditable(newValueEditable);
  }, [editable, resetField, defaultValue, source]);

  const callback = useCallback(async (
    resource: string | undefined,
    params: Partial<UpdateParams> | undefined,
    options: UseUpdateOptions | undefined,
    next: UpdateMutationFunction,
  ) => {
    const existingSuccessCallback = options?.onSuccess;
    const newSuccessCallback: UseUpdateOptions['onSuccess'] = existingSuccessCallback ? (...args) => {
      setEditable(false);
      return existingSuccessCallback(...args);
    } : () => {
      setEditable(false);
    };
    const newOptions = {
      ...options,
      onSuccess: newSuccessCallback,
    };
    return next(resource, params, newOptions);
  }, []);

  useRegisterMutationMiddleware<UpdateMutationFunction>(callback);

  if (!editable) {
    const FieldComponent = typeFields[type];
    return (
      <Box>
        <Typography variant="smallTitle">{label}</Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
          <FieldComponent source={source} emptyText="-" />
          {showEdit && (
            <Button startIcon={<CreateIcon color="secondary" />} size="small" onClick={handleChange}>
              Edit
            </Button>
          )}
        </Box>
      </Box>
    );
  }

  const InputComponent = typeInputs[type];
  return (
    <Box>
      <Typography variant="smallTitle">{label}</Typography>
      <Box display="flex" alignItems="baseline" justifyContent="space-between" flexWrap="wrap">
        <InputComponent
          color="secondary"
          autoFocus
          label=""
          source={source}
          validate={validate}
        />
        <Button startIcon={<CancelIcon color="error" />} size="small" onClick={handleChange}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default EditField;
