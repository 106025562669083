import React from 'react';

import {
  Edit,
  TextField,
  DateField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  usePermissions,
  ArrayField,
  EditButton as RaEditButton,
  useRecordContext,
  SimpleForm,
} from 'react-admin';

import { joiResolver } from '@hookform/resolvers/joi';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import Joi from '../../utilities/joiExtension';
import hasAccess from '../../utilities/hasAccess';

import ReferenceMemberField from '../../customFields/ReferenceMemberField';
import ReferenceEntityField from '../../customFields/ReferenceEntityField';
import LatestRelatedRequests from '../../customFields/LatestRelatedRequests';
import LatestRelatedCases from '../../customFields/LatestRelatedCases';
import LatestRelatedPings from '../../customFields/LatestRelatedPings';

import Pagination from '../layout/Pagination';

import RiskManagement from '../riskManagment';
import AccountEntityBreadcrumb from './AccountEntityBreadcrumb';
import Transaction from '../transaction';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import { resourceFromEntityTypes } from '../../constants/entityTypes';
import actions, { Actions } from '../../constants/actions';
import { countryChoices, countryCodes } from '../../constants/countries';
import languages from '../../constants/languages';
import RelatedCustomConnections from '../connection/RelatedCustomConnections';
import { ConnectedEntity } from '../../utilities/schemas/accountEntity';
import bankAccountTypes, { bankAccountTypesChoices } from '../../constants/bankAccountTypes';
import bankAccountStatuses, { bankAccountStatusesChoices } from '../../constants/bankAccountStatuses';
import useValidateId from '../../hooks/useValidateId';
import resourceTypes from '../../constants/resourceTypes';
import CustomToolbar from '../../customFields/CustomToolbar';
import EditArrayInput from '../../customFields/EditArrayInput';
import EditField from '../../customFields/EditField';
import EditSelectInput from '../../customFields/EditSelectInput';

const ConnectedEntitiesEditButton = () => {
  const record = useRecordContext<ConnectedEntity>();

  if (!record?.type) return <Typography>-</Typography>;

  const resource = resourceFromEntityTypes[record.type];
  return <RaEditButton resource={resource} />;
};

const accountUpdateSchema = Joi.object({
  groups: Joi.array().items(Joi.string()),
  customIds: Joi.array().items(Joi.string()),
  provider: Joi.string(),
  nonBankingInstitution: Joi.boolean(),
  source: Joi.string(),
  opened: Joi.date(),
  closed: Joi.date(),
  status: Joi.string().valid(...Object.values(bankAccountStatuses)),
  iban: Joi.string(),
  bban: Joi.string(),
  bic: Joi.string(),
  country: Joi.string().valid(...countryCodes),
  accountName: Joi.string(),
  product: Joi.string(),
  accountType: Joi.string().valid(...Object.values(bankAccountTypes)),
  bankgiroNumber: Joi.string(),
  plusgiroNumber: Joi.string(),
}).options({ stripUnknown: true });

const AccountEntityEdit = () => {
  const { permissions } = usePermissions<Actions[]>();
  const validatedId = useValidateId();
  if (!validatedId.isValid) return null;

  return (
    <Edit
      redirect={false}
      mutationMode="pessimistic"
    >
      <AccountEntityBreadcrumb />
      <TabbedShowLayout>
        <Tab label="Information">
          <SimpleForm
            mode="all"
            toolbar={
              <CustomToolbar sx={{ marginBottom: -6, marginLeft: -4, marginRight: -4 }} />
            }
            sx={{ padding: 0 }}
            component={Box}
            resolver={joiResolver(accountUpdateSchema)}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5}>
                <Card variant="outlined">
                  <CardHeader title="Account Details" />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <EditField showEdit source="accountName" label="Account Name" />
                      </Grid>
                      <Grid item container xs={12} md={12} spacing={2}>
                        <Grid item xs={12} md={6}>
                          <EditArrayInput showEdit source="customIds" label="Custom IDs" />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <EditField showEdit source="provider" label="Provider" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <EditField showEdit type="date" source="createdAt" label="Created At" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <EditField showEdit source="iban" label="IBAN" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <EditField showEdit source="bic" label="BIC" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <EditSelectInput
                          showEdit
                          source="country"
                          label="Country"
                          choices={countryChoices(languages.EN)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <EditField showEdit source="bban" label="BBAN" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <EditField showEdit source="product" label="Product" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <EditSelectInput
                          showEdit
                          source="accountType"
                          label="Account Type"
                          choices={bankAccountTypesChoices}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <EditSelectInput
                          showEdit
                          source="status"
                          label="Status"
                          choices={bankAccountStatusesChoices}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <EditField showEdit type="date" source="opened" label="Opened Date" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <EditField showEdit type="date" source="closed" label="Closed Date" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <EditField showEdit source="bankgiroNumber" label="Bankgiro" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <EditField showEdit source="plusgiroNumber" label="Plusgiro" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <EditField showEdit type="boolean" source="nonBankingInstitution" label="Issued by non banking institution" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <EditField showEdit source="source" label="Source" />
                      </Grid>
                      <Grid item xs={12}>
                        <RiskManagement />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={7} container direction="column">
                <Grid item>
                  <Card variant="outlined">
                    <CardHeader title="Account holders" />
                    <Divider />
                    <ArrayField source="connectedEntities">
                      <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                        <ReferenceEntityField sortable={false} source="" label="Id" />
                        <TextField sortable={false} source="type" />
                        <ConnectedEntitiesEditButton />
                      </Datagrid>
                    </ArrayField>
                  </Card>
                </Grid>
                <Grid item>
                  <RelatedCustomConnections />
                </Grid>
                <Grid item>
                  <RelatedCustomConnections />
                </Grid>
              </Grid>
              <LatestRelatedRequests />
              <LatestRelatedCases />
              <LatestRelatedPings />
            </Grid>
          </SimpleForm>
        </Tab>
        {hasAccess(permissions, actions.REQUEST_GET) && (
          <Tab label="Transactions">
            <Transaction />
          </Tab>
        )}
        {hasAccess(permissions, actions.AUDIT_TRACK_LIST) && (
          <Tab label="Audit Tracks">
            <Grid container>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <ReferenceManyField
                    reference="audit-tracks"
                    target="resource.id"
                    filter={{ 'resource.type': resourceTypes.ACCOUNT_ENTITY }}
                    sort={{ field: 'date', order: 'DESC' }}
                    perPage={15}
                    pagination={<Pagination disableEmptyText />}
                  >
                    <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                      <TextField sortable={false} source="id" />
                      <ReferenceMemberField sortable={false} label="User" />
                      <TextField sortable={false} source="description" />
                      <DateField sortable source="date" showTime />
                    </Datagrid>
                  </ReferenceManyField>
                </Card>
              </Grid>
            </Grid>
          </Tab>
        )}
      </TabbedShowLayout>
    </Edit>
  );
};

export default AccountEntityEdit;
