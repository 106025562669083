import React from 'react';

import conflictFields, { ConflictFields } from '../../constants/conflictFields';

import FirstName from '../kycForm/view/answers/FirstName';
import CompanyName from '../kycForm/view/answers/CompanyName';
import LastName from '../kycForm/view/answers/LastName';
import OrgNumber from '../kycForm/view/answers/OrgNumber';
import Tin from '../kycForm/view/answers/Tin';
import City from '../kycForm/view/answers/City';
import Residence from '../kycForm/view/answers/Residence';
import Email from '../kycForm/view/answers/Email';
import PostalCode from '../kycForm/view/answers/PostalCode';
import Address from '../kycForm/view/answers/Address';
import Website from '../kycForm/view/answers/Website';
import PhoneNumber from '../kycForm/view/answers/PhoneNumber';
import Signatories from '../kycForm/view/answers/Signatories';
import { LegalFormAnswer } from './fields/LegalForm';
import BeneficialOwner from '../kycForm/view/answers/BeneficialOwner';
import IsFinancialInstitution from '../kycForm/view/answers/IsFinancialInstitution';
import Industry from '../kycForm/view/answers/Industry';
import Activities from '../kycForm/view/answers/Activities';
// import PersonalNumber from '../components/kycForm/view/answers/PersonalNumber';
import { BeneficialOwnersDatapointField } from './fields/BeneficialOwners';
import { AlternativeBeneficialOwnersDatapointField } from './fields/AlternativeBeneficialOwners';
import OwnerStructure from '../kycForm/view/answers/OwnerStructure';
import Salary from '../kycForm/view/answers/Salary';
import Citizenship from '../kycForm/view/answers/Citizenship';
import EmploymentStatus from '../kycForm/view/answers/EmploymentStatus';
import { PurposeAnswer } from './fields/Purpose';
import Products from '../kycForm/view/answers/Products';
import TaxResidency from '../kycForm/view/answers/TaxResidency';
import Pep from '../kycForm/view/answers/Pep';
import Role from '../kycForm/view/answers/Role';
import StateOwned from '../kycForm/view/answers/StateOwned';
import ForecastedTurnover from '../kycForm/view/answers/ForecastedTurnover';
import PreviousTurnover from '../kycForm/view/answers/PreviousTurnover';
import ListedFinancialMarket from '../kycForm/view/answers/ListedFinancialMarket';
import DateOfBirth from '../kycForm/view/answers/DateOfBirth';
import { Languages } from '../../constants/languages';
import OperationStartDate from '../kycForm/view/answers/OperationStartDate';
import { TransactionMonitoringThresholdsAnswer } from './fields/TransactionMonitoringThresholdsForm';
import { SourceOfFundsAnswer } from './fields/SourceOfFunds';
import { SourceOfWealthAnswer } from './fields/SourceOfWealth';

const conflictFieldsAnswerFields: Record<
ConflictFields,
React.ComponentType<{ language?: Languages }>
> = {
  [conflictFields.FIRST_NAME]: FirstName.ValueField,
  [conflictFields.LAST_NAME]: LastName.ValueField,
  [conflictFields.COMPANY_NAME]: CompanyName.ValueField,
  [conflictFields.REGISTRATION_NUMBER]: OrgNumber.ValueField,
  [conflictFields.TIN]: Tin.ValueField,
  [conflictFields.CITY]: City.ValueField,
  [conflictFields.RESIDENCE]: Residence.ValueField,
  [conflictFields.EMAIL]: Email.ValueField,
  [conflictFields.POSTAL_CODE]: PostalCode.ValueField,
  [conflictFields.WEBSITE]: Website.ValueField,
  [conflictFields.PHONE_NUMBER]: PhoneNumber.ValueField,
  [conflictFields.ADDRESS]: Address.ValueField,
  [conflictFields.SIGNATORIES]: Signatories.ValueField,
  [conflictFields.BENEFICIAL_OWNER]: BeneficialOwner.ValueField,
  [conflictFields.BENEFICIAL_OWNERS]: BeneficialOwnersDatapointField,
  [conflictFields.ALTERNATIVE_BENEFICIAL_OWNERS]: AlternativeBeneficialOwnersDatapointField,
  [conflictFields.OWNER_STRUCTURE]: OwnerStructure.ValueField,
  [conflictFields.LEGAL_FORM]: LegalFormAnswer,
  [conflictFields.IS_FINANCIAL_INSTITUTION]: IsFinancialInstitution.ValueField,
  [conflictFields.INDUSTRY]: Industry.ValueField,
  [conflictFields.ACTIVITIES]: Activities.ValueField,
  [conflictFields.TRANSACTION_MONITORING_THRESHOLD]: TransactionMonitoringThresholdsAnswer,
  [conflictFields.PURPOSE]: PurposeAnswer,
  [conflictFields.PRODUCTS]: Products.ValueField,
  [conflictFields.TAX_RESIDENCY]: TaxResidency.ValueField,
  [conflictFields.PEP]: Pep.ValueField,
  [conflictFields.CITIZENSHIP]: Citizenship.ValueField,
  [conflictFields.EMPLOYMENT_STATUS]: EmploymentStatus.ValueField,
  [conflictFields.INCOME]: Salary.ValueField,
  [conflictFields.SOURCE_OF_FUNDS]: SourceOfFundsAnswer,
  // [conflictFields.PERSONAL_NUMBER]: PersonalNumber.ValueField,
  [conflictFields.ROLE]: Role.ValueField,
  [conflictFields.STATE_OWNED]: StateOwned.ValueField,
  [conflictFields.FORECASTED_TURNOVER]: ForecastedTurnover.ValueField,
  [conflictFields.PREVIOUS_TURNOVER]: PreviousTurnover.ValueField,
  [conflictFields.LISTED_FINANCIAL_MARKET]: ListedFinancialMarket.ValueField,
  [conflictFields.DATE_OF_BIRTH]: DateOfBirth.ValueField,
  [conflictFields.SOURCE_OF_WEALTH]: SourceOfWealthAnswer,
  [conflictFields.MIDDLE_NAME]: () => null,
  [conflictFields.OTHER_LEGAL_FORM]: () => null,
  [conflictFields.OPERATION_START_DATE]: OperationStartDate.ValueField,
  // [conflictFields.CONTROL_TYPE]: PrimitiveInput,
  // [conflictFields.FAMILY_CONTROL]: PrimitiveInput,
  // [conflictFields.DIRECT_CONTROL]: PrimitiveInput,
} as const;

export default conflictFieldsAnswerFields;
