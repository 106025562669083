import React from 'react';

import { useRecordContext } from 'react-admin';

import PartyForm from './forms';

const Edit = () => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <PartyForm type={record.type} />
  );
};

export default Edit;
