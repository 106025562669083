import React, { useCallback } from 'react';

import {
  Typography,
  ToggleButtonGroup,
  ToggleButton as MuiToggleButton,
  Stack,
} from '@mui/material';

import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import { useInput } from 'react-admin';

const ToggleButton = () => {
  const { field } = useInput({ source: 'includeGoods', defaultValue: false });
  const onChangeIsMyClient = field.onChange;

  const handleChange = useCallback((
    _event: React.MouseEvent<HTMLElement>,
    newValue: boolean | null,
  ) => {
    if (newValue !== null) {
      onChangeIsMyClient(newValue);
    }
  }, [onChangeIsMyClient]);

  return (
    <Stack direction="row" spacing={4}>
      <ToggleButtonGroup
        value={field.value}
        exclusive
        onChange={handleChange}
        size="small"
        color="primary"
      >
        <MuiToggleButton disabled value="">
          <DirectionsCarFilledIcon />
        </MuiToggleButton>
        <MuiToggleButton value>
          <Typography>Include</Typography>
        </MuiToggleButton>
        <MuiToggleButton value={false}>
          <Typography>Exclude</Typography>
        </MuiToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};

export default ToggleButton;
