import React from 'react';

import questions, { Questions } from '../../../constants/questions';

import { QuestionElementTypes } from '../../../constants/questionElementTypes';

import CompanyName from './answers/CompanyName';
import OrgNumber from './answers/OrgNumber';
import Tin from './answers/Tin';
import City from './answers/City';
import Residence from './answers/Residence';
import Email from './answers/Email';
import PhoneNumber from './answers/PhoneNumber';
import PostalCode from './answers/PostalCode';
import Address from './answers/Address';
import Signatories from './answers/Signatories';
import BeneficialOwner from './answers/BeneficialOwner';
import BeneficialOwners from './answers/BeneficialOwners';
import AssignedOwners from './answers/AssignedOwners';
import Website from './answers/Website';
import Activities from './answers/Activities';
import OwnerStructure from './answers/OwnerStructure';
import LegalForm from './answers/LegalForm';
import IsFinancialInstitution from './answers/IsFinancialInstitution';
import Industry from './answers/Industry';
import StateOwned from './answers/StateOwned';
import ForecastedTurnover from './answers/ForecastedTurnover';
import PreviousTurnover from './answers/PreviousTurnover';
import OperationStartDate from './answers/OperationStartDate';

import { Languages } from '../../../constants/languages';

import ExpectedTransactionBehavior from './answers/ExpectedTransactionBehaviour';
import Purpose from './answers/Purpose';
import Products from './answers/Products';
import TaxResidency from './answers/TaxResidency';
import Pep from './answers/Pep';
import Citizenship from './answers/Citizenship';
import Salary from './answers/Salary';
import EmploymentStatus from './answers/EmploymentStatus';
import SourceOfFunds from './answers/SourceOfFunds';
import FirstName from './answers/FirstName';
import LastName from './answers/LastName';
import PersonalNumber from './answers/PersonalNumber';
import ControlType from './answers/ControlType';
import FamilyControl from './answers/FamilyControl';
import { IQuestionElement } from '../types';
import Signer from './answers/Signer';
import Role from './answers/Role';
import Custom from './answers/Custom';
import ListedFinancialMarket from './answers/ListedFinancialMarket';
import DateOfBirth from './answers/DateOfBirth';
import SourceOfWealth from './answers/SourceOfWealth';
import SourceOfWealthAmount from './answers/SourceOfWealthAmount';
import FileUpload from './answers/FileUpload';

type ExtraParametersObj = {
  disabled?: boolean;
  label?: string
}

type ExtraParameters = {
  label?: string,
  options?: { id: string, label: string }[]
  type?: QuestionElementTypes;
  incoming?: {
    disabled?: boolean,
    volume?: ExtraParametersObj,
    frequency?: ExtraParametersObj,
    max?: ExtraParametersObj,
    median?: ExtraParametersObj,
    expectedTransactionTimeline: ExtraParametersObj,
  }
  outgoing?: {
    disabled?: boolean,
    volume?: ExtraParametersObj,
    frequency?: ExtraParametersObj,
    max?: ExtraParametersObj,
    median?: ExtraParametersObj,
    expectedTransactionTimeline: ExtraParametersObj,
  }
  process?: {
    disabled?: boolean,
    volume?: ExtraParametersObj,
    frequency?: ExtraParametersObj,
    max?: ExtraParametersObj,
    median?: ExtraParametersObj,
    expectedTransactionTimeline: ExtraParametersObj,
  }
  total?: {
    disabled?: boolean,
    volume?: ExtraParametersObj,
    frequency?: ExtraParametersObj,
    max?: ExtraParametersObj,
    median?: ExtraParametersObj,
    expectedTransactionTimeline: ExtraParametersObj,
  }
  unit?: 'day' | 'month' | 'year';
  period?: number;
  skipDetails?: boolean;
  multiple?: boolean;
}

export type Question = {
  ({
    extraParameters, name, source,
  }: {
    name?: string;
    extraParameters?: ExtraParameters;
    source?: string;
    question: Questions;
  }): React.ReactNode;
  ValueField: ({ language, extraParameters }: {
    language?: Languages;
    extraParameters?: ExtraParameters
    formId?: string;
  }) => React.JSX.Element | null;
  Nested?: ({ source }: {
    source?: string;
  }) => React.JSX.Element;
}

export type ISummary = IQuestionElement & {
  id: Questions
}

const answersComponents: Record<Questions, Question> = {
  [questions.Q_ACTIVITIES]: Activities,
  [questions.Q_ADDRESS]: Address,
  [questions.Q_ASSIGNED_OWNERS]: AssignedOwners,
  [questions.Q_BENEFICIAL_OWNER]: BeneficialOwner,
  [questions.Q_BENEFICIAL_OWNERS]: BeneficialOwners,
  [questions.Q_CITIZENSHIP]: Citizenship,
  [questions.Q_CITY]: City,
  [questions.Q_COMPANY_NAME]: CompanyName,
  [questions.Q_CONTROL_TYPE]: ControlType,
  [questions.Q_CUSTOM]: Custom,
  [questions.Q_DATE_OF_BIRTH]: DateOfBirth,
  [questions.Q_EMAIL]: Email,
  [questions.Q_EMPLOYMENT_STATUS]: EmploymentStatus,
  [questions.Q_FAMILY_CONTROL]: FamilyControl,
  [questions.Q_FILE_UPLOAD]: FileUpload,
  [questions.Q_FIRST_NAME]: FirstName,
  [questions.Q_FORECASTED_TURNOVER]: ForecastedTurnover,
  [questions.Q_INDUSTRY]: Industry,
  [questions.Q_IS_FINANCIAL_INSTITUTION]: IsFinancialInstitution,
  [questions.Q_LAST_NAME]: LastName,
  [questions.Q_LEGAL_FORM]: LegalForm,
  [questions.Q_LISTED_FINANCIAL_MARKET]: ListedFinancialMarket,
  [questions.Q_OPERATION_START_DATE]: OperationStartDate,
  [questions.Q_ORG_NUMBER]: OrgNumber,
  [questions.Q_OWNER_STRUCTURE]: OwnerStructure,
  [questions.Q_PEP]: Pep,
  [questions.Q_PERSONAL_NUMBER]: PersonalNumber,
  [questions.Q_PHONE_NUMBER]: PhoneNumber,
  [questions.Q_POSTAL_CODE]: PostalCode,
  [questions.Q_PREVIOUS_TURNOVER]: PreviousTurnover,
  [questions.Q_PRODUCTS]: Products,
  [questions.Q_PURPOSE]: Purpose,
  [questions.Q_RESIDENCE]: Residence,
  [questions.Q_ROLE]: Role,
  [questions.Q_SALARY]: Salary,
  [questions.Q_SIGNATORIES]: Signatories,
  [questions.Q_SIGNER]: Signer,
  [questions.Q_EXPECTED_TRANSACTION_BEHAVIOUR]: ExpectedTransactionBehavior,
  [questions.Q_SOURCE_OF_FUNDS]: SourceOfFunds,
  [questions.Q_SOURCE_OF_WEALTH_AMOUNT]: SourceOfWealthAmount,
  [questions.Q_SOURCE_OF_WEALTH]: SourceOfWealth,
  [questions.Q_STATE_OWNED]: StateOwned,
  [questions.Q_TAX_RESIDENCY]: TaxResidency,
  [questions.Q_TIN]: Tin,
  [questions.Q_WEBSITE]: Website,
};

export default answersComponents;
