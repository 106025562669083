import React, { useCallback, useState } from 'react';

import {
  Tabs,
  Tab,
  Divider,
  Box,
  Slide,
  Button,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import Goods from './Goods';

import entityTypes, { EntityTypes } from '../../../../../constants/entityTypes';
import GoodsForm from './forms';

const Create = () => {
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [value, setValue] = useState<EntityTypes>(entityTypes.CAR);

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: EntityTypes) => {
    setValue(newValue);
  }, []);

  const handleOpenChange = useCallback(() => {
    setEditOpen((prev) => !prev);
  }, []);

  return (
    <>
      <Slide direction="right" in={!editOpen} appear={false}>
        <Box display={editOpen ? 'none' : 'block'}>
          <Tabs value={value} onChange={handleChangeTab}>
            <Tab value={entityTypes.CAR} label="Cars" />
          </Tabs>
          <Divider />
          <Box marginTop={4}>
            {value === entityTypes.CAR && (
              <Goods
                type={entityTypes.CAR}
                handleOpenChange={handleOpenChange}
              />
            )}
          </Box>
        </Box>
      </Slide>
      <Slide direction="left" in={editOpen}>
        <Box display={!editOpen ? 'none' : 'block'}>
          <Box marginBottom={4}>
            <Button startIcon={<ArrowBackIosNewIcon />} onClick={handleOpenChange}>
              Back
            </Button>
          </Box>
          <GoodsForm type={value} />
        </Box>
      </Slide>
    </>
  );
};

export default Create;
