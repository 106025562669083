import React, { useEffect } from 'react';

import {
  Box, Grid, Typography,
} from '@mui/material';

import { isEmpty } from 'lodash';

import {
  TextInput,
  required,
} from 'react-admin';

import { useFormContext, useWatch } from 'react-hook-form';

import ArrayInput from './base/ArrayInput';
import cleaner from '../../../utilities/dataProvider/cleaner';

const ProductsDialogBody = () => (
  <Grid container columnSpacing={6}>
    <Grid item xs={12} md={12}>
      <TextInput fullWidth source="products" label="products" />
    </Grid>
  </Grid>

);

const ItemLabel = ({ value } : {value: Record<string, string>}) => {
  if (isEmpty(value)) return null;

  return (
    <Box display="flex" alignItems="center" gap={5}>
      <Typography
        variant="h6"
        color="primary.main"
      >
        {value.products}
      </Typography>
    </Box>
  );
};

const Products = () => {
  const value = useWatch({ name: '_value' });
  const { setValue } = useFormContext();
  useEffect(() => {
    setValue('value', (value ?? []).flatMap((v: {products: string }) => v.products), { shouldDirty: true });
  }, [setValue, value]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Typography
          color="#646464"
          variant="h6"
          marginBottom={4}
        >
          Products
        </Typography>
        <ArrayInput
          rootField="_value"
          title="Add product"
          itemNameSource="products"
          ItemLabel={ItemLabel}
          DialogBody={ProductsDialogBody}
          validate={required()}
          transformItemData={cleaner}
        />
      </Grid>
    </Grid>
  );
};

export default Products;
