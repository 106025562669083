import React from 'react';

import {
  ArrayInput,
  TextField,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Divider,
} from '@mui/material';

import Create from './dialog/Create';
import Edit from './dialog/Edit';

import DatagridIterator from '../../../../customFields/DatagridIterator';

const Goods = () => (
  <ArrayInput source="report.activity.goods_services" label={false}>
    <Box>
      <Card variant="outlined">
        <CardHeader title="Goods" />
        <Divider />
        <DatagridIterator
          rowContent={[
            <TextField sortable={false} source="recordId" label="Id" />,
            <TextField sortable={false} source="item.item_type" label="Goods type" />,
            <TextField sortable={false} source="registrationNumber" label="Registration number" />,
          ]}
          addDialogContent={(
            <Create />
          )}
          editDialogContent={(
            <Edit />
          )}
        />
      </Card>
    </Box>
  </ArrayInput>
);

export default Goods;
