import React from 'react';

import {
  DateField,
  TextField,
} from 'react-admin';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Box,
  Divider,
} from '@mui/material';

import ColoredScoreField from '../../../../../../customFields/ColoredScoreField';

import PepTitle from './PepTitle';

const PepMatchInformation = () => (
  <Grid item xs={12} md={12}>
    <Card variant="outlined">
      <CardHeader title="Match information" />
      <Divider />
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <PepTitle>Provider name</PepTitle>
            <TextField source="providerName" emptyText="-" />
          </Box>
          <Box>
            <PepTitle>Match %</PepTitle>
            <ColoredScoreField source="matchProbability" />
          </Box>
          <Box>
            <PepTitle>Last updated</PepTitle>
            <DateField showTime source="createdAt" emptyText="-" />
          </Box>
        </Box>
      </CardContent>
    </Card>
  </Grid>
);

export default PepMatchInformation;
