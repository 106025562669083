import React, { memo } from 'react';

import PersonIcon from '@mui/icons-material/Person';

import { NodeProps } from 'reactflow';

import Node from './Node';
import { NodeData } from './utils';

const IndividualNode = ({ data, selected }: NodeProps<NodeData>) => (
  <Node
    selected={selected}
    data={data}
    Icon={PersonIcon}
  />
);

export default memo(IndividualNode);
