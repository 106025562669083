import React, { useCallback } from 'react';

import {
  Typography,
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup,
  Stack,
} from '@mui/material';
import { useInput } from 'react-admin';

import PersonIcon from '@mui/icons-material/Person';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

import entityTypes from '../../../../../../../constants/entityTypes';

const ToggleButton = ({
  direction,
}: {
  direction: string,
}) => {
  const {
    field: isMyClientField,
  } = useInput({ source: `${direction}.isMyClient` });
  const onChangeIsMyClient = isMyClientField.onChange;
  const {
    field: entityTypeField,
  } = useInput({ source: `${direction}.type` });
  const onChangeEntityType = entityTypeField.onChange;

  const handleChangeIndividual = useCallback((
    _event: React.MouseEvent<HTMLElement>,
    newValue: boolean | null,
  ) => {
    if (newValue !== null) {
      onChangeIsMyClient(newValue);
      onChangeEntityType(entityTypes.INDIVIDUAL);
    }
  }, [onChangeIsMyClient, onChangeEntityType]);

  const handleChangeBusiness = useCallback((
    _event: React.MouseEvent<HTMLElement>,
    newValue: boolean | null,
  ) => {
    if (newValue !== null) {
      onChangeIsMyClient(newValue);
      onChangeEntityType(entityTypes.BUSINESS);
    }
  }, [onChangeEntityType, onChangeIsMyClient]);

  return (
    <Stack direction="row" spacing={4}>
      <ToggleButtonGroup
        value={entityTypeField.value === entityTypes.INDIVIDUAL ? isMyClientField.value : null}
        exclusive
        onChange={handleChangeIndividual}
        size="small"
        color="primary"
      >
        <MuiToggleButton disabled value="">
          <PersonIcon />
        </MuiToggleButton>
        <MuiToggleButton value>
          <Typography>My client</Typography>
        </MuiToggleButton>
        <MuiToggleButton value={false}>
          <Typography>Not my client</Typography>
        </MuiToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        value={entityTypeField.value === entityTypes.BUSINESS ? isMyClientField.value : null}
        exclusive
        onChange={handleChangeBusiness}
        size="small"
        color="primary"
      >
        <MuiToggleButton disabled value="">
          <CorporateFareIcon />
        </MuiToggleButton>
        <MuiToggleButton value>
          <Typography>My client</Typography>
        </MuiToggleButton>
        <MuiToggleButton value={false}>
          <Typography>Not my client</Typography>
        </MuiToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};

export default ToggleButton;
