import React, { useState } from 'react';

import {
  ListBase,
} from 'react-admin';

import {
  Card,
} from '@mui/material';

import Filter from './RequestFilter';

import Pagination from '../../../../layout/Pagination';

import { requestListMap } from '../../../../../constants/requestFeatures';
import { RequestTypes } from '../../../../../constants/requestTypes';
import PrefillButton from '../../../utils/PrefillButton';
import { prefillRequest } from './RequestForm';

const Requests = ({
  handleOpenChange,
  entities,
  requests,
}: {
  handleOpenChange: () => void,
  entities?: string[],
  requests?: string[],
}) => {
  const [filter, setFilter] = useState<{
    requestType: RequestTypes;
    'custom.entity'?: string[];
    'id'?: string[];
  }>({
    requestType: 'transaction',
    'custom.entity': entities,
    id: requests,
  });

  const RequestListComponent = requestListMap[filter.requestType];

  return (
    <ListBase
      filter={filter}
      perPage={10}
      resource="requests"
      disableSyncWithLocation
      storeKey="fipo-requests"
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Filter filter={filter} setFilter={setFilter} />
      <Card variant="outlined">
        <RequestListComponent>
          <PrefillButton
            handleOpenChange={handleOpenChange}
            prefillForm={prefillRequest}
          />
        </RequestListComponent>
      </Card>
      <Pagination
        rowsPerPageOptions={[10, 15, 20]}
        disableEmptyText
      />
    </ListBase>
  );
};

export default Requests;
