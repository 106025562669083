import React from 'react';

import {
  ArrayInput,
  TextField,
  useRecordContext,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Divider,
} from '@mui/material';

import DatagridIterator from '../../../../customFields/DatagridIterator';
import Create from './dialog/Create';
import Edit from './dialog/Edit';

const NameField = ({ label }: {label: string}) => {
  const record = useRecordContext();
  if (!record) return null;

  if (Object.keys(record).includes('t_person')) {
    const name = `${record?.t_person?.first_name} ${record?.t_person?.last_name}`;
    return <TextField record={{ name }} source="name" label={label} />;
  }
  if (Object.keys(record).includes('t_entity')) {
    const name = record?.t_entity.name;
    return <TextField record={{ name }} source="name" label={label} />;
  }
  return null;
};

const Parties = () => (
  <ArrayInput source="report.activity.report_parties" label={false}>
    <Box>
      <Card variant="outlined">
        <CardHeader title="Parties" />
        <Divider />
        <DatagridIterator
          rowContent={[
            <TextField sortable={false} source="recordId" label="Id" />,
            <NameField label="Name" />,
          ]}
          addDialogContent={(
            <Create />
          )}
          editDialogContent={(
            <Edit />
          )}
        />
      </Card>
    </Box>
  </ArrayInput>
);

export default Parties;
