import React, { useCallback } from 'react';

import {
  Form,
  required,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
  useResourceContext,
  useUpdate,
} from 'react-admin';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { useParams } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import { FieldValues } from 'react-hook-form';
import useDialogStatus from '../../hooks/useDialogStatus';

const EditComment = () => {
  const refresh = useRefresh();
  const record = useRecordContext();
  const notify = useNotify();
  const resource = useResourceContext();
  const { id } = useParams();
  const defaultMessage = record?.editions?.length
    ? record.editions[record.editions.length - 1].message
    : record?.message;

  const {
    open,
    openDialog,
    closeDialog,
  } = useDialogStatus();

  const [update] = useUpdate();

  const handleComment = useCallback((data: FieldValues) => {
    update(
      resource,
      {
        id: `${id}/comment`,
        data: {
          comment: data.comment,
          origin: record?._id,
        },
      },
      {
        onSuccess: () => {
          closeDialog();
          refresh();
        },
        onError: () => {
          notify('Could not create comment', { type: 'error' });
        },
      },
    );
  }, [update, resource, id, record?._id, closeDialog, refresh, notify]);

  return (
    <>
      <Button
        startIcon={<Edit fontSize="inherit" />}
        onClick={openDialog}
        size="small"
        sx={{
          minWidth: 'auto',
          color: 'grey',
          padding: 0,
          paddingLeft: '4px',
          paddingRight: '4px',
          fontSize: 'inherit',
          '.MuiButton-startIcon': {
            marginRight: '2px',
          },
        }}
      >
        Edit
      </Button>

      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xs">
        <Form values={{ comment: defaultMessage }} onSubmit={handleComment}>
          <DialogTitle>Edit comment</DialogTitle>
          <DialogContent>
            <TextInput
              source="comment"
              multiline
              margin="dense"
              fullWidth
              validate={[required()]}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

export default EditComment;
