import React from 'react';

import {
  maxLength,
  NumberInput,
  required,
  SelectInput,
  TextInput,
} from 'react-admin';

import { Grid } from '@mui/material';

import entityTypes from '../../../../../../constants/entityTypes';
import { ICar } from '../../../../../../utilities/schemas/car';

export type CarFormValues = {
  item: {
    item_type: string,
    registration_number?: string,
    estimated_value?: number,
    description: string,
  }
}

export const prefillCar = (
  record: ICar,
) => ({
  recordId: record.id,
  type: entityTypes.CAR,
  item: {
    item_type: 'FORDB',
    registration_number: record.registrationNumber,
    estimated_value: undefined,
    description: undefined,
  },
});

const Car = () => (
  <Grid container spacing={6}>
    <Grid item xs={12} sm={6} md={3}>
      <SelectInput choices={[{ id: 'FORDB', name: 'Car' }]} disabled fullWidth source="item.item_type" label="Type" validate={required()} />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput fullWidth source="item.registration_number" label="Registration number" />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <NumberInput fullWidth source="item.estimated_value" label="Valuation" />
    </Grid>
    <Grid item xs={12} sm={12} md={12}>
      <TextInput fullWidth multiline minRows={3} label="Description of the item" source="item.description" validate={[required(), maxLength(4000)]} />
    </Grid>
  </Grid>
);

export default Car;
