import actions, { Actions } from '../../../constants/actions';
import ruleCategories, { RuleCategories } from './ruleCategories';

const ruleCategoriesPermissions: Record<RuleCategories, Actions[]> = {
  [ruleCategories.TRANSACTION]: [
    actions.REQUEST_GET,
  ],
  [ruleCategories.KYC]: [
    actions.INDIVIDUAL_GET,
    actions.BUSINESS_GET,
    actions.CAR_GET,
  ],
  [ruleCategories.CAR]: [
    actions.CAR_GET,
  ],
  [ruleCategories.TEST]: [
    actions.INDIVIDUAL_GET,
    actions.BUSINESS_GET,
    actions.CAR_GET,
    actions.ACCOUNT_ENTITY_GET,
  ],
};

export default ruleCategoriesPermissions;
