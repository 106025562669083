import actions, { Actions } from '../../../constants/actions';
import ruleSubCategories, { RuleSubCategories } from './ruleSubCategories';

const ruleSubCategoriesPermissions: Record<RuleSubCategories, Actions[]> = {
  [ruleSubCategories.CAR_KYC]: [
    actions.CAR_GET,
  ],
  [ruleSubCategories.CAR_PURCHASE]: [
    actions.CAR_GET,
  ],
  [ruleSubCategories.KYC_INDIVIDUAL]: [
    actions.INDIVIDUAL_GET,
  ],
  [ruleSubCategories.KYC_BUSINESS]: [
    actions.BUSINESS_GET,
  ],
  [ruleSubCategories.BUYER]: [
    actions.CAR_GET,
  ],
  [ruleSubCategories.SELLER]: [
    actions.CAR_GET,
  ],
  [ruleSubCategories.INITIATOR]: [
    actions.REQUEST_GET,
  ],
  [ruleSubCategories.RECIPIENT]: [
    actions.REQUEST_GET,
  ],
  [ruleSubCategories.PROCESSOR]: [
    actions.REQUEST_GET,
  ],
  [ruleSubCategories.TRANSACTION]: [
    actions.REQUEST_GET,
  ],
  [ruleSubCategories.TEST]: [
    actions.INDIVIDUAL_GET,
    actions.BUSINESS_GET,
    actions.CAR_GET,
    actions.ACCOUNT_ENTITY_GET,
  ],
};

export default ruleSubCategoriesPermissions;
