import React from 'react';

import { useRecordContext } from 'react-admin';

import GoodsForm from './forms';

const Edit = () => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <GoodsForm type={record.type} />
  );
};

export default Edit;
