import React, { useCallback, useState } from 'react';

import {
  Button,
  Slide,
  Box,
  Tab,
  Tabs,
  Divider,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { useParams } from 'react-router-dom';
import { useGetOne } from 'react-admin';

import Requests from './Requests';
import RequestForm from './RequestForm';
import { ICase } from '../../../../../utilities/schemas/case';

const Create = () => {
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [value, setValue] = useState<'case' | 'all' | 'custom'>('case');

  const { id } = useParams();
  const { data } = useGetOne<ICase>('cases', { id: id! }, { enabled: !!id });

  const handleChangeTab = useCallback((_event: React.SyntheticEvent, newValue: 'case' | 'all' | 'custom') => {
    setValue(newValue);
  }, []);

  const handleOpenChange = useCallback(() => {
    setEditOpen((prev) => !prev);
  }, []);

  return (
    <>
      <Slide direction="right" in={!editOpen} appear={false}>
        <Box display={editOpen ? 'none' : 'block'}>
          <Tabs value={value} onChange={handleChangeTab}>
            <Tab value="case" label="Case Requests" />
            <Tab value="all" label="All Requests" />
            <Tab value="custom" label="Custom" />
          </Tabs>
          <Divider />
          <Box marginTop={4}>
            {value === 'case' && data && <Requests requests={data?.requests} handleOpenChange={handleOpenChange} />}
            {value === 'all' && data && <Requests handleOpenChange={handleOpenChange} entities={data?.entities.map((entity) => entity.id)} />}
            {value === 'custom' && (
              <RequestForm />
            )}
          </Box>
        </Box>
      </Slide>
      <Slide direction="left" in={editOpen}>
        <Box display={!editOpen ? 'none' : 'block'}>
          <Box marginBottom={4}>
            <Button startIcon={<ArrowBackIosNewIcon />} onClick={handleOpenChange}>
              Back
            </Button>
          </Box>
          <RequestForm />
        </Box>
      </Slide>
    </>
  );
};

export default Create;
