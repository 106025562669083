import { Node, Edge, MarkerType } from 'reactflow';

// eslint-disable-next-line import/extensions, import/no-extraneous-dependencies
import ELK, { ElkNode } from 'elkjs/lib/elk.bundled.js';

import {
  isEmpty, maxBy,
} from 'lodash';
import React from 'react';

import theme from '../../../../theme';

export type EdgeData = {
  volume: number,
  frequency: number,
  grouped: string[],
  switchStates: ('forward' | 'reversed')[],
}

export type NodeData = {
  label: string;
  isRoot?: boolean;
}

export type TransactionFlowData = {
  id: string,
  edges: Edge<EdgeData>[]
  nodes: Node<NodeData>[]
}

const freqColor = (frequencyRatio: number) => {
  if (frequencyRatio > 0.80) return theme.palette.red.main; // red

  if (frequencyRatio > 0.50) return theme.palette.orange.main; // orange

  if (frequencyRatio > 0.20) return theme.palette.yellow.main; // yellow

  return theme.palette.primary.main; // default to green
};

const getStyle = (
  edge: Edge<EdgeData>,
  maxVolume: number,
  maxFreq: number,
) => {
  const volume = edge.data?.volume ?? 1;
  const frequency = edge.data?.frequency ?? 1;

  const strokeWidth = Math.min(4, Math.max(1, Math.round((volume / maxVolume) * 3 + 1)));

  const color = freqColor(frequency / maxFreq);

  return {
    strokeWidth,
    color,
  };
};

export const getPositions = async ({ edges, nodes }: {
  edges: Edge<EdgeData>[],
  nodes: Node<NodeData>[]
}) => {
  if (isEmpty(edges) && isEmpty(nodes)) {
    return {
      dataEdges: [],
      dataNodes: [],
    };
  }

  const graph: ElkNode = {
    id: 'root',
    children: nodes.map((node) => ({
      ...node,
      width: 60,
      height: 60,
      data: node.data,
    })),
    edges: edges.map((edge) => ({
      ...edge,
      sources: [edge.source],
      targets: [edge.target],
    })),
  };

  const elk = new ELK({
    defaultLayoutOptions: {
      'elk.algorithm': 'layered',
      'elk.direction': 'DOWN',
      'elk.spacing.nodeNode': '50',
      'elk.layered.spacing.nodeNodeBetweenLayers': '100',
      'elk.layered.spacing': '75',
      'elk.spacing': '75',
      'elk.spacing.individual': '75',
      'elk.edgeRouting': 'SPLINES',
    },
  });

  const graphResult = await elk.layout(graph);

  const maxVolume = maxBy(edges, (edge) => edge.data?.volume)?.data?.volume ?? 1;

  const maxFreq = maxBy(edges, (edge) => edge.data?.frequency)?.data?.frequency ?? 1;

  return {
    dataEdges: graphResult.edges?.map((edge, i) => {
      const { color, strokeWidth } = getStyle(edges[i], maxVolume, maxFreq);

      return {
        ...edges[i],
        ...edge,
        style: {
          strokeWidth,
          stroke: color,
        },
        animated: edges[i].selected,
        ...(edges[i].data?.switchStates.includes('forward') ? {
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color,
          },
        } : {}),
        ...(edges[i].data?.switchStates.includes('reversed') ? {
          markerStart: {
            type: MarkerType.ArrowClosed,
            color,
          },
        } : {}),
      };
    }),
    dataNodes: graphResult.children?.map((child, i) => ({
      ...nodes[i],
      ...child,
      position: { x: child.x ?? 0, y: child.y ?? 0 },
    })),
  };
};

export const updateTree = async ({
  data,
  setEdges,
  setNodes,
}: {
  data: TransactionFlowData[];
  setEdges: React.Dispatch<React.SetStateAction<Edge<EdgeData>[]>>;
  setNodes: React.Dispatch<React.SetStateAction<Node<NodeData>[]>>;
}) => {
  const positionedTree = await getPositions({
    edges: data.at(0)!.edges,
    nodes: data.at(0)!.nodes,
  });
  if (positionedTree?.dataEdges) setEdges(positionedTree.dataEdges);
  if (positionedTree?.dataNodes) setNodes(positionedTree.dataNodes);
};
