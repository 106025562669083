import React, { memo } from 'react';

import BusinessIcon from '@mui/icons-material/Business';

import { NodeProps } from 'reactflow';

import { NodeData } from './utils';
import Node from './Node';

const BusinessNode = ({ data, selected }: NodeProps<NodeData>) => (
  <Node
    selected={selected}
    data={data}
    Icon={BusinessIcon}
  />
);

export default memo(BusinessNode);
