import React, { useCallback } from 'react';
import { DateTime } from 'luxon';
import { Box, styled } from '@mui/material';
import {
  DateInput,
  Form,
  SelectInput,
  required,
  useRecordContext,
} from 'react-admin';
import { Currencies } from '../../constants/currencies';
import Params from './Params';
import Summary from './Summary';
import { requestTypes } from '../../constants/requestTypes';
import { EntityTypes } from '../../constants/entityTypes';
import SubmitOnChange from '../utils/SubmitOnChange';

const rangeValidation = (_value: string, allValues: Params) => {
  const { from, to } = allValues;

  if (from && to) {
    if (DateTime.fromISO(to) < DateTime.fromISO(from)) {
      return 'Invalid date range';
    }
  }
  return undefined;
};

const StyledForm = styled(Form)({
  width: '100%',
});

const TransactionFilterForm = ({
  params,
  filter,
  setParams,
}: {
  params: Params;
  filter: {
    from: string;
    to: string;
    currency: string;
    entityId: string;
    entityType: EntityTypes;
    requestType: typeof requestTypes.TRANSACTION;
  };
  setParams: React.Dispatch<React.SetStateAction<Params>>;
}) => {
  const record = useRecordContext<{
    currencies?: Currencies[]
  }>();

  const onSubmit = useCallback((newParams: Params) => {
    setParams((previousParams) => ({ ...previousParams, ...newParams }));
  }, [setParams]);

  return (
    <StyledForm values={params}>
      <Box display="flex" gap={4} flexWrap="wrap" alignItems="baseline">
        <SelectInput
          label="Currency"
          source="currency"
          choices={record?.currencies?.map((currency) => ({
            id: currency,
            name: currency,
          }))}
          validate={required()}
        />
        <DateInput source="from" label="From" validate={[required(), rangeValidation]} />
        <DateInput source="to" label="To" validate={[required(), rangeValidation]} />
        <SubmitOnChange onSubmit={onSubmit} />
      </Box>
      <Summary params={params} filter={filter} />
    </StyledForm>
  );
};

export default TransactionFilterForm;
