import React from 'react';

import {
  Box,
  Card,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';

import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  ReferenceField,
  usePermissions,
  useRecordContext,
} from 'react-admin';

import hasAccess from '../../utilities/hasAccess';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import { Actions } from '../../constants/actions';

import EditComment from './EditComment';
import CommentHistory from './CommentHistory';
import { IComment } from './IComments';
import CreateComment from './CreateComment';

const CommentMessageField = ({
  emptyText,
  action,
}: {
  emptyText?: string,
  label: string,
  action: Actions,
}) => {
  const comment = useRecordContext<IComment>();
  const { permissions } = usePermissions<Actions[]>();
  if (!comment) return null;

  const message = comment.editions?.length
    ? comment.editions[comment.editions.length - 1].message
    : comment.message;

  const isEdited = !!comment.editions?.length;

  return (
    <>
      <Typography
        component="p"
        variant="body2"
      >
        {message || emptyText}
      </Typography>
      <Typography component="p" variant="caption" color="grey" marginTop={2}>
        {isEdited ? (
          <>
            <Box component="span" sx={{ marginRight: '2px' }}>Edited</Box>
            {' - '}
            <CommentHistory />
            {hasAccess(permissions, action) && (
              <>
                {' - '}
                <EditComment />
              </>
            )}
          </>
        ) : <EditComment />}
      </Typography>
    </>
  );
};

const CommentList = ({ source, action }: {source: string, action: Actions}) => {
  const { permissions } = usePermissions<Actions[]>();

  return (
    <Card variant="outlined">
      <CardHeader
        title="Comments"
        action={hasAccess(permissions, action) && <CreateComment />}
      />
      <Divider />
      <ArrayField source={source}>
        <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
          <CommentMessageField emptyText="-" label="Comment" action={action} />
          <DateField showTime source="date" />
          <ReferenceField label="Author" source="member" reference="members" link="show">
            <FunctionField render={(member: { firstName: string, lastName: string }) => `${member.firstName} ${member.lastName}`} />
          </ReferenceField>
        </Datagrid>
      </ArrayField>
    </Card>
  );
};

export default CommentList;
