import React from 'react';

import {
  DateInput,
  required,
  TextInput,
} from 'react-admin';

import { Grid } from '@mui/material';
import { IIndividual } from '../../../../../../utilities/schemas/individual';
import entityTypes from '../../../../../../constants/entityTypes';

export type PersonFormValues = {
  recordId: string,
  type: typeof entityTypes.INDIVIDUAL,
  t_person: {
    first_name: string,
    last_name: string,
    ssn?: string,
    birthdate?: string,
  }
}

export const prefillPerson = (
  record: IIndividual,
) => ({
  recordId: record.id,
  type: entityTypes.INDIVIDUAL,
  t_person: {
    first_name: record.firstName,
    last_name: record.lastName,
    ssn: record.swedishSocialSecurityNumber,
    birthdate: record.birthDate,
  },
});

const Person = () => (
  <Grid container spacing={6}>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput fullWidth source="t_person.first_name" label="First name" validate={required()} />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput fullWidth source="t_person.last_name" label="Last name" validate={required()} />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput fullWidth source="t_person.ssn" label="Swedish social security number" />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <DateInput fullWidth source="t_person.birthdate" label="Birth date" />
    </Grid>
  </Grid>
);

export default Person;
