import React, { useCallback, useState } from 'react';
import {
  Tabs,
  Tab,
  Slide,
  Box,
  Button,
  Divider,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import Entities from './Entities';

import entityTypes, { EntityTypes } from '../../../../../constants/entityTypes';
import PartyForm from './forms';

const Create = () => {
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [value, setValue] = useState<EntityTypes>(entityTypes.INDIVIDUAL);

  const handleChangeTab = useCallback((_event: React.SyntheticEvent, newValue: EntityTypes) => {
    setValue(newValue);
  }, []);

  const handleOpenChange = useCallback(() => {
    setEditOpen((prev) => !prev);
  }, []);

  return (
    <>
      <Slide direction="right" in={!editOpen} appear={false}>
        <Box display={editOpen ? 'none' : 'block'}>
          <Tabs value={value} onChange={handleChangeTab}>
            <Tab value={entityTypes.INDIVIDUAL} label="Individuals" />
            <Tab value={entityTypes.BUSINESS} label="Businesses" />
          </Tabs>
          <Divider />
          <Box marginTop={4}>
            {value === entityTypes.INDIVIDUAL && (
              <Entities
                type={value}
                handleOpenChange={handleOpenChange}
              />
            )}
            {value === entityTypes.BUSINESS && (
              <Entities
                type={value}
                handleOpenChange={handleOpenChange}
              />
            )}
          </Box>
        </Box>
      </Slide>
      <Slide direction="left" in={editOpen}>
        <Box display={!editOpen ? 'none' : 'block'}>
          <Box marginBottom={4}>
            <Button startIcon={<ArrowBackIosNewIcon />} onClick={handleOpenChange}>
              Back
            </Button>
          </Box>
          <PartyForm type={value} />
        </Box>
      </Slide>
    </>
  );
};

export default Create;
