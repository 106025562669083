import {
  Box,
  InputAdornment,
  Popover,
} from '@mui/material';

import React, { useCallback } from 'react';

import SquareIcon from '@mui/icons-material/Square';

import { ChromePicker } from 'react-color';
import { useFormContext, useWatch } from 'react-hook-form';
import { TextField, TextInput, useRecordContext } from 'react-admin';
import { get } from 'lodash';

export const ColorField = ({
  source,
  label,
}: {
  source: string;
  label?: string;
}) => {
  const record = useRecordContext();
  const value = get(record, source);
  return (
    <Box display="flex" gap={2} alignItems="center">
      <TextField
        label={label}
        source={source}
      />
      <SquareIcon sx={{ color: value }} fontSize="small" />
    </Box>
  );
};

const PickerDialog = ({
  color,
  anchorEl,
  onChange,
  handlePopoverClose,
}: {
  color: string;
  anchorEl: HTMLElement | null;
  onChange: ({ hex }: { hex: string; }) => void
  handlePopoverClose: () => void
}) => {
  const open = Boolean(anchorEl);
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <ChromePicker
        onChange={onChange}
        color={color}
      />
    </Popover>
  );
};

const ColorPicker = ({
  source,
  label,
}: {
  source: string,
  label: string
}) => {
  const { setValue } = useFormContext();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const color = useWatch({ name: source });

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onChange = useCallback(({ hex } : {hex: string}) => {
    setValue('color', hex, { shouldDirty: true });
  }, [setValue]);

  return (
    <>
      <TextInput
        label={label}
        defaultValue="#000000"
        source={source}
        onClick={handlePopoverOpen}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SquareIcon sx={{ color }} fontSize="small" />
            </InputAdornment>),
        }}
      />
      <PickerDialog
        handlePopoverClose={handlePopoverClose}
        onChange={onChange}
        color={color}
        anchorEl={anchorEl}
      />
    </>
  );
};

export default ColorPicker;
