import ruleCategories, { RuleCategories } from './ruleCategories';
import ruleSubCategories, { RuleSubCategories } from './ruleSubCategories';

const ruleCategoriesMapping: Record<RuleCategories, RuleSubCategories[]> = {
  [ruleCategories.CAR]: [
    ruleSubCategories.CAR_KYC,
    ruleSubCategories.CAR_PURCHASE,
    ruleSubCategories.BUYER,
    ruleSubCategories.SELLER,
  ],
  [ruleCategories.KYC]: [
    ruleSubCategories.CAR_KYC,
    ruleSubCategories.KYC_INDIVIDUAL,
    ruleSubCategories.KYC_BUSINESS,
  ],
  [ruleCategories.TRANSACTION]: [
    ruleSubCategories.INITIATOR,
    ruleSubCategories.RECIPIENT,
    ruleSubCategories.PROCESSOR,
    ruleSubCategories.TRANSACTION,
  ],
  [ruleCategories.TEST]: [
    ruleSubCategories.TEST,
  ],
};

export default ruleCategoriesMapping;
